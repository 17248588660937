import './index.scss'
import Walkzero from "../../../assets/images/walk.jpg"
import {useAnimation, motion} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { container2, child1, child2 } from '../../../variants'


const TeamView = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    return(
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='d-flex flex-row justify-content-between align-items-center align-self-center team-view-cont p-t-80 p-b-80'>
            <motion.img variants={child1} src={Walkzero} alt='team view' className='team-view-img'/>
            <motion.div variants={child2} className='d-flex flex-column align-items-start view-cont'>
                <h1 className="col-container-font h2-size mb-0">Why were we named <span className='col-secondary'>“Walkzero”?</span></h1>
                <p className='font font-weight-3 p-size'>The name "Walkzero" captures our dedication to empowering businesses to effortlessly advance toward excellence in their management practices. "Walk" represents progressive movement and constant advancement, while "zero" highlights our commitment to removing inefficiencies and obstacles, offering a pathway of zero effort. This synergy allows startups, business owners, and entrepreneurs to approach us without any existing systems or processes and effortlessly leave with a software solution perfectly tailored to their specific business and industry needs.</p>
            </motion.div>
        </motion.div>
    )
}

export default TeamView