import "./index.scss"
import {
    AreaChart as Areachart,
    XAxis as XAxisComponent,
    YAxis as YAxisComponent,
    CartesianGrid,
    Area
  } from "recharts"
import AnimatedNumber from "../../animation/number"


type AreaChartProps = {
  item:{
    id: string
    title: string
    status: string 
    value: number 
    icon: JSX.Element
    color: string 
    fill: string
    data: any[]
  }
}


const AreaChart = (props:AreaChartProps) => {

    const {item} = props
    const {id, title, status, value, icon, color, fill, data} = item

    

    return(
        <div className="bg-col-white rounded-25 p-3 m-2 position-relative area-chart">
            <h2 className="text-12 font-weight-5 mb-0 font-1">{title}</h2>
            <span className="text-8 font-weight-2 font-1">{status}</span>
            <Areachart className="move-area" id={id}
              width={140}
              height={75}
              data={data}
              margin={{
                top: 0,
                right: 25,
                left: 0,
                bottom: 40,
              }}
            >
              <CartesianGrid vertical={false} horizontal={false} />
              <XAxisComponent stroke="#a0aec0" dataKey="month" hide={true} axisLine={false} tickLine={false} />
              <YAxisComponent stroke="#a0aec0" tickCount={7} domain={[0, "dataMax + 100"]} hide={true} axisLine={false} tickLine={false} />
              <Area type="monotone" dataKey="app" strokeWidth={1.5} stroke={color} fill={fill} />
            </Areachart>
            <h3 className="text-12 mb-0 font-1 position-absolute bottom-0 pb-3"><AnimatedNumber key={value} num={value} /> Person<span className="m-l-10">{icon}</span></h3>
        </div>
    )
}

export default AreaChart