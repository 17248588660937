import { DemoBtn } from '../../../styled-components'
import './index.scss'
import { Link } from 'react-router-dom'
import { MenuContext } from '../../../app'
import { useContext } from 'react'


const OurTeam = () => {

    const {isOn} = useContext(MenuContext)

    return(

        <div className='our-team-cont d-flex flex-column justify-content-center align-items-center align-self-center'>
            <p className='font font-weight-3 p-size text-center hide-mb'>Meet the vibrant team behind Walkzero, a diverse group of talented individuals united by a shared passion for revolutionising the technology landscape. From seasoned industry experts to innovative young professionals, each team member contributes unique insights and skills that drive our success.</p>
            <div className="investor-btn m-t-20">
                <Link to='/book-a-demo' className="demo">
                    <DemoBtn isOn={isOn} className="rounded-10 font-bold text-14 font-weight-700 book-demo-btn w-100 p-y-10 btn-4-alternate">Join Our Team</DemoBtn>
                </Link>
            </div>
        </div>
    )
}

export default OurTeam