import "./index.scss"
import Rectangle from "../../../assets/images/wz-app.png"
import {ReactComponent as Ess} from "../../../assets/logos/ess.svg"
import {ReactComponent as Calendar} from "../../../assets/logos/calendar.svg"
import {ReactComponent as Analytics} from "../../../assets/logos/analytics.svg"
import {ReactComponent as Payroll} from "../../../assets/logos/payroll.svg"
import {ReactComponent as Holiday} from "../../../assets/logos/holiday.svg"
import {ReactComponent as GeoFence} from "../../../assets/logos/geo-fence.svg"
import {ReactComponent as SelfOnboard} from "../../../assets/logos/self-onboard.svg"
import Part1 from "../../../assets/images/part1.png"
import Part2 from "../../../assets/images/part2.png"
import Part3 from "../../../assets/images/part3.png"
import Part4 from "../../../assets/images/part4.png"
import Part5 from "../../../assets/images/part5.png"
import Part6 from "../../../assets/images/part6.png"
import Part7 from "../../../assets/images/part7.png"

import PlatformItem from "../platform-items"
import FocusOnSelect from "../platform-mobile-slide"
import Vector from "../../../assets/images/wz-logo.png"
import { useState } from "react"
import {motion, useAnimation} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";

const data = [
    {id:"icon0", icon:<Analytics width={17} height={17}/>, name:"HR Hub", content:"Centralizes HR functions for streamlined management and collabration.", isClicked:false},
    {id:"icon1", icon:<Ess width={17} height={17}/>, name:"Employee Self-Service", content:"Empowers employees for efficient HR task management and access.", isClicked:false},
    {id:"icon2", icon:<SelfOnboard width={17} height={17} />, name:"Self-Onboarding", content:"Digitizes and accelerates new hire integration for seamless onboarding.", isClicked:false},
    {id:"icon3", icon:<Holiday width={17} height={17} />, name:"Leave Management", content:"Automates leave requests for efficient tracking and approval processes.", isClicked:false},
    {id:"icon4", icon:<Calendar width={17} height={17} />, name:"Attendance Management", content:"Ensures accurate timekeeping and compliance with attendance policies.", isClicked:false},
    {id:"icon5", icon:<GeoFence width={17} height={17} />, name:"Geo-Tracking", content:"Enhances attendance tracking with location-based boundaries and restrictions.", isClicked:false},
    {id:"icon6", icon:<Payroll width={17} height={17} />, name:"Payroll Processing", content:"Automates payroll tasks for accurate, timely salary disbursement.", isClicked:false},
]


const Platform = () => {
    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    const [feature, setFeature] = useState(data);
    const [activeId, setActiveId] = useState<string | null>(null);

    const clickFeature = (id:string) => {
        setFeature(prevFeature => prevFeature.map(each => {
            if(each.id === id){
                if(each.id === activeId){
                    setActiveId(null)
                    return {...each, isClicked: false}
                }
                setActiveId(id)
                return {...each, isClicked: !each.isClicked};
            }
            return {...each, isClicked: false};
        }));
    }

    const container = {
        hidden:{
            opacity:1
        },
        visible: (i = 1) => ({
            opacity:1,
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * i,
            }
        }),
    }

    const child = {
        visible:{
            opacity:1,
            transition:{
                duration:1,
                staggerChildren: 0.45,
                delayChildren: 0.2,
            }
        },
        hidden:{
            opacity:0,
        }
    }

    return(
        <div className="outside-container position-relative">
            <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} className="overall-container">
                <motion.div variants={child} className="platform-container">
                    <span className="slogan2">CATALYZE YOUR COMPANY'S GROWTH</span>
                    <h1 className="head">One Platform, All Solutions: <span className="orange">Simplifying</span> Your Every Need</h1>
                    <p className="content m-t-16">Streamline your organizational demands effortlessly with our comprehensive platform, managing operations from hire to retire.</p>
                    <motion.ul variants={child}  className="platform-list-cont hide-feature">
                        {feature.map(each => {
                            return <PlatformItem key={each.id} item={each} clickFeature={clickFeature} />
                        })}
                    </motion.ul>
                    {/* <ul className="platform-list-cont show-mob-feature"> */}
                    <FocusOnSelect data={feature} clickFeature={clickFeature} />
                    {/* </ul> */}
                </motion.div>
                <div className="position-relative w-75 hide-platform-in-mob">
                    <img style={activeId !==null?{filter:"blur(5px)"}:{}} className="platform-img" src={Rectangle} alt="platform" />
                    <motion.img animate={{scale:activeId === "icon0"?1.2:1, opacity:activeId === "icon0"?1:0, originX:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 left-percentage-7 top-105 w-46" src={Part1} alt="HR Hub" />
                    <motion.img animate={{scale:activeId === "icon1"?1.2:1, opacity:activeId === "icon1"?1:0, originX:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 right-percentage-22 top-105 w-24" src={Part2} alt="Employee Self-Service" />
                    <motion.img animate={{scale:activeId === "icon2"?1.2:1, opacity:activeId === "icon2"?1:0, x:activeId === "icon2"?-20:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 right-25 top-105 w-20" src={Part3} alt="Self-Onboarding" />
                    <motion.img animate={{scale:activeId === "icon3"?1.2:1, opacity:activeId === "icon3"?1:0, originX:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 left-percentage-8 top-percentage-35 w-45" src={Part4} alt="Leave Management" />
                    <motion.img animate={{scale:activeId === "icon4"?1.2:1, opacity:activeId === "icon4"?1:0, originX:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 right-percentage-23 top-percentage-25 w-23" src={Part5} alt="Attendance Management" />
                    <motion.img animate={{scale:activeId === "icon5"?1.2:1, opacity:activeId === "icon5"?1:0, originX:0, x:activeId === "icon5"?-35:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 right-25 bottom-55 w-20" src={Part6} alt="Geofencing" />
                    <motion.img animate={{scale:activeId === "icon6"?1.2:1, opacity:activeId === "icon6"?1:0, originX:0, transition:{duration:1.3, ease:"easeInOut"}}} className="shad position-absolute z-index-1 left-percentage-8 top-percentage-49 w-69" src={Part7} alt="Payroll Processing" />
                </div>
                <div className="mob-platform">
                    {activeId === "icon0" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-85" src={Part1} alt="HR Hub" />}
                    {activeId === "icon1" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-84" src={Part2} alt="Employee Self-Service" />}
                    {activeId === "icon2" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-70" src={Part3} alt="Self-Onboarding" />}
                    {activeId === "icon3" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-85" src={Part4} alt="Leave Management" />}
                    {activeId === "icon4" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-60" src={Part5} alt="Attendance Management" />}
                    {activeId === "icon5" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-45" src={Part6} alt="Geofencing" />}
                    {activeId === "icon6" && <motion.img animate={{scale:1.1, transition:{duration:1.3, ease:"easeInOut"}}} className="shad w-90" src={Part7} alt="Payroll Processing" />}
                </div>
            </motion.div>
            <div className="side-container position-absolute  right-30  top-140 p-0">
                <img className="curve" src={Vector} alt="vector" />
            </div>
        </div>
    )
}

export default Platform