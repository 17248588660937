import "./index.scss"
import { Link, useParams } from "react-router-dom"
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import {ReactComponent as Illustration2} from "../../../assets/logos/illustration2.svg"
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import { data2 } from "../../product-menu/product-menu";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../app";
import { VERIFY_ACCOUNT } from "../../../graphql/queries"
import { TailSpin } from "react-loader-spinner"

const url = `${process.env.REACT_APP_WZ_URL}`;

const status = {
    initial:"Initial",
    inProgress:"Progress",
    success:"Success",
    failed:"Failed"
}

const AccountVerified = () => {

    const {setIsOpen, setIsProductOpen, setModules} = useContext(MenuContext)
    const params = useParams()
    const token = params.id

    const [isVerified, setVerify] = useState(false)
    const [msg, setMsg] = useState('')
    const [progress, setProgress] = useState(status.initial)

    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        setModules(data2)
    }


    useEffect(() => {
        const verifyAccount = async() => {
            setProgress(status.inProgress)
            try {
                const response = await fetch(url, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                    query: VERIFY_ACCOUNT,
                    variables: { token },
                    }),
                })
    
                if (response.ok) {
                    
                    const res = await response.json();
                    const {data, errors} = res
                    if(data){
                        setProgress(status.success)
                        console.log(data)
                    }
                    if(errors){
                        setProgress(status.success)
                        setVerify(true)
                        setMsg(errors[0].token[0])
                    }
                    return res;
                }
            }
            catch(error:any){
                console.error(`error in verify account: ${error.message}`)
            }    
        }
        verifyAccount()
    }, [token])


    return(
        <>
        <div className='w-100 d-flex flex-row justify-content-start align-self-center signup-wz-logo1'>
            <Link to="/" onClick={clickLogo}><Logo width={"73%"} className="logo-height m-t-30"/></Link>
        </div> 
        <div style={{minHeight:"90vh"}} className="w-100 grad d-flex flex-row align-items-start">
            <div className="d-flex flex-column align-items-center bg-col-white sign-up-first-cont1 p-t-30 p-b-10">
                <div className="position-relative d-flex flex-row justify-content-center ellipse m-t-40 w-70">
                    <Illustration2 />
                </div>
                <h1 className="text-24 font-weight-6 m-t-100 text-center">"Fasten your seatbelt and get <br/> ready for the ride."</h1>
            </div>
            <div style={{justifyContent:"center"}} className="position-relative sign-up-second-cont1 bg-col-container-bg d-flex flex-column p-t-30 p-b-10">
                {progress === status.inProgress  && <div className="align-self-center"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                {progress === status.success &&<>
                    {isVerified &&<div className="msg-cont d-flex flex-column align-items-center position-relative">
                    <h2 className="col-secondary text-22 mb-0 align-self-center m-t-10 text-center">{msg}</h2>
                </div>} 
                {!isVerified && <><div className="d-flex flex-column align-items-center signup-form-cont align-self-center position-relative">
                    
                    <h2 className="col-container-font text-22 mb-0 m-t-10">Your account is verified <span className='col-secondary'>successfully</span></h2>
                    <p className="text-13 font-weight-4 font-1 col-copyrights-clr m-t-10 m-b-0 text-center">We’re working on your account activation. You'll be notified to your register email within 24 hrs</p>
                    <p className="font-1 font-weight-6 text-20 col-container-font m-t-10">Thank you for choosing walkzero</p>
                    <Star className="position-absolute top--60 right--30" />
                    <Star style={{width:20, height:20}} className="position-absolute top--30 right--40" />
                </div></>}
                <p style={{position:"absolute", bottom:50, left:0, right:0}} className="text-12 font-weight-4 font-1 col-copyrights-clr text-center mb-0">Copyright © 2024 - walkzero.com</p>
                <p style={{position:"absolute", bottom:20, left:0, right:0}} className="text-12 font-weight-4 font-1 col-copyrights-clr text-center mt-0">All Rights Reserved</p>
                </>}
                {isVerified && <>
                <Star className="position-absolute top-60 right-50" />
                <Star style={{width:20, height:20}} className="position-absolute top-90 right-80" /></>}
            </div>
        </div> 
        </>
    )
}

export default AccountVerified