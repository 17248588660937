import './index.scss'
import { Link } from 'react-router-dom'
import {motion} from "framer-motion"

type BlogItemsProps = {
    item : {
        id: string
        title: string 
        short_description: string 
        photo: string
        slug: string
    }
}

const BlogItems = (props: BlogItemsProps) => {

    const {item} = props
    const {title, short_description, photo, slug} = item

    return(
            <motion.div className='d-flex flex-column justify-content-start align-items-start blog-items-cont'>
                <Link to={`/blog/${slug}`} className='decoration'>
                    <motion.h3 className='text-16 font font-weight-6'>{title}</motion.h3>
                    <motion.p className='text-12 font font-weight-3'>{short_description}</motion.p>
                    <motion.img className='w-95 m-l--15' src={photo} alt='blog' />
                </Link>
            </motion.div>
    )
}

export default BlogItems