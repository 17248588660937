import React from "react";
import Slider from "react-slick"

import "./index.scss"

export type SliderSettings = {
    dots: boolean;
    infinite: boolean;
    slidesToShow: number;
    slidesToScroll: number;
    autoplay: boolean;
    speed: number;
    autoplaySpeed: number;
    cssEase: string;
    centerMode: boolean;
    pauseOnHover: boolean;
}

export type AutoPlay2Props = {
    data:{id: string; icon: JSX.Element; name: string}[];
    settings: SliderSettings
}

function AutoPlay2(props:AutoPlay2Props) {

    const {data, settings} = props

    return (
      <div className="slider-container m-t-40 slide-size3 align-self-center mask">
        <Slider {...settings}>
          {data.map(each => {
            return <span key={each.id} className="d-flex flex-row justify-content-center align-items-center mb-3">{each.icon} {each.name}</span>
          })}
        </Slider>
      </div>
    );
}

export default AutoPlay2