import "./index.scss"
import {ReactComponent as Polygon} from "../../../assets/logos/polygon.svg"
import {ReactComponent as Union} from "../../../assets/logos/union.svg"
import Mobile from "../../../assets/images/mobile.png"
import SignupButton from "../../layout/button"
import {useAnimation, motion} from "framer-motion"
import { useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import { MenuContext } from "../../../app"
import { BaseSvg2 } from "../../../styled-components"


const Workforce = () => {

    const ctrls = useAnimation();

    const {isOn} = useContext(MenuContext)

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const container = {
        hidden:{
            opacity:1
        },
        visible: (i = 1) => ({
            opacity:1,
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * i,
            }
        }),
    }

    const child = {
        visible:{
            opacity:1,
            transition:{
                duration:1, 
                type:"tween",
                ease:"easeInOut"
            }
        },
        hidden:{
            opacity:0
        }
    }

    return(
        <div className="outer-workforce position-relative">
            <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} className="inner-workforce">
                <img src={Mobile} alt="walkzero app" width="45%" className="mobile-img" />
                <motion.div variants={child} className="workforce-content">
                    <span className="slogan2 z-index-1">CLOUD-BASED HR MANAGEMENT SOLUTION THAT DOES IT ALL</span>
                    <h1 className="head z-index-1">The most complete HRMS platform for the <span className="orange">modern workforce</span></h1>
                    <p className="content">Walkzero provides all-encompassing HRMS & Payroll solutions, optimizing HR processes, boosting employee engagement, and fostering organizational success. With its cutting-edge features and intuitive interface, Walkzero enables businesses to adapt to the evolving demands of the modern workforce seamlessly and efficiently.</p>
                    <ul className="feature-list">
                        <li><BaseSvg2 isOn={isOn}><Polygon /></BaseSvg2>Empowering team potential</li>
                        <li><BaseSvg2 isOn={isOn}><Polygon /></BaseSvg2>Maximizing workplace productivity</li>
                        <li><BaseSvg2 isOn={isOn}><Polygon /></BaseSvg2>Streamlining operational efficiency</li>
                        <li><BaseSvg2 isOn={isOn}><Polygon /></BaseSvg2>Elevating performance standards</li>
                        <li><BaseSvg2 isOn={isOn}><Polygon /></BaseSvg2>Accelerating organizational growth</li>
                    </ul>
                    <SignupButton />
                </motion.div>
            </motion.div>
            <Union className="position-absolute left-0 bottom-30 p-0"  />
        </div>
    )
}

export default Workforce