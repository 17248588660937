import {ReactComponent as GreenLine} from '../../../../assets/logos/pink-line.svg'
import './index.scss'
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';
import { container, child4 } from "../../../../variants"

const line = <GreenLine id='line'/>

const merits = [
    {id:"merit1", icon:line, title:"Enhanced Employee Productivity", cont:"Perform tasks, manage leave, and stay updated from anywhere, boosting productivity and workflow efficiency."},
    {id:"merit2", icon:line, title:"Streamlined HR Processes", cont:"Manage leave requests and attendance remotely, reducing workload and enhancing operational efficiency."},
    {id:"merit3", icon:line, title:"Improved Team Coordination", cont:"Instant updates on availability foster seamless coordination and communication, speeding up collaboration and decision-making."}
]

const AttendanceMerits = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

  return (
    <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} id="Merits" className='pro-merits d-flex flex-row justify-content-center'>
        <motion.ul className='pro-center d-flex flex-row justify-content-between align-items-start'>
            {merits.map((each)=> {
                return <motion.li className='d-flex flex-column justify-content-center merits-cont' key={each.id}>
                    <div className='d-flex flex-row'>
                        <span className='green-line'>{each.icon}</span>
                        <motion.h3 variants={child4} className='text-18 font-weight-600 merit-head'>{each.title}</motion.h3>
                    </div>
                    <p className=' font-weight-400 p-size merit-para'>{each.cont}</p>
                </motion.li>
            })}
        </motion.ul>
    </motion.div>
  )
}
export default AttendanceMerits