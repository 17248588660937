import { useEffect, useState } from "react";
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2, child} from "../../../../variants"

import {ReactComponent as SlideIcon} from '../../../../assets/logos/ess-slide-icon.svg'
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'

const hrData = [
    {id:"data1", icon:<SlideIcon />, title:"Mobile ESS Access", content:"Perform self-service tasks from any location at any time and receive instant notifications about team leave and attendance, ensuring you stay informed even when away from your desk."},
    {id:"data2", icon:<SlideIcon />, title:"Personal and Job Profile Management", content:"Update and review personal and employment details directly from your mobile device, and access the organization's structure to understand reporting lines and team composition."},
    {id:"data3", icon:<SlideIcon />, title:"Leave Management", content:"View leave policies, available leave types, balances, and leave history, and easily apply for leave via the mobile app. Managers can also view and take action on team leave requests."},
    {id:"data4", icon:<SlideIcon />, title:"Attendance Management", content:"Access attendance policies and guidelines, including work-from-home and overtime regulations, and punch in and out from the mobile app, making attendance management effortless."},
    {id:"data5", icon:<SlideIcon />, title:"Self-Onboarding Process", content:"Reduce paperwork and save time for HR staff and new employees with a paperless onboarding process that can be completed from anywhere with an internet connection"},
    {id:"data6", icon:<SlideIcon />, title:"Comprehensive ESS Solutions", content:"Handle everything from updating personal details to managing leave and attendance with a single mobile app, ensuring a smooth and hassle-free experience for employees and managers alike."},
]

const AttendanceSlide = () => {

    const [scrollRight, setScrollRight] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [data] = useState(hrData);

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });

    useEffect(() => {
        const updateDimensions = () => {
            const scrollingContainer:any = document.getElementById("hrSlideContainer");
            const child:any = document.getElementById("hrChild");
            const scrollAmount = Math.floor(scrollingContainer.offsetWidth / (child.offsetWidth))
            const right = hrData.length - scrollAmount
            setScrollRight(right)
        }

        updateDimensions();

        window.addEventListener("resize", updateDimensions);

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
        
    }, [data, ctrls, inView])

    const slideRight = () => {

        const scrollingContainer:any = document.getElementById("hrSlideContainer");
        const child:any = document.getElementById("hrChild");
       
        setScrollRight(prevScrollRight => prevScrollRight - 1);
        setScrollLeft(prevScrollLeft => prevScrollLeft + 1);
    
        
        scrollingContainer.scrollLeft += (child.offsetWidth + 40)

    }

    const slideLeft = () => {

        const scrollingContainer:any = document.getElementById("hrSlideContainer");
        const child:any = document.getElementById("hrChild");

        setScrollLeft(prevScrollLeft => prevScrollLeft - 1);
        setScrollRight(prevScrollRight => prevScrollRight + 1);

       
        scrollingContainer.scrollLeft -= (child.offsetWidth + 40)
       
    }

    const shouldShowLeftArrow = scrollLeft > 0;
    const shouldShowRightArrow = scrollRight > 0;

    
  return (
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrSlide" className='hr-slide-par p-t-50 mb-40 d-flex flex-column'>
        <motion.div variants={child} className='hr-slide-child  d-flex flex-column  align-self-center'>
            <h3 className='h1-size font-weight-600 text-center'><span className="col-secondary">All-in-One Mobile ESS</span> and Onboarding Solutions for Employees</h3>
            <div className='hr-slide-one d-flex flex-column align-self-center'>
                <p className='font-1 p-size font-weight-400 text-center'>Enjoy a seamless experience managing your employment and onboarding. Handle everything from personal information to leave requests conveniently from your phone.</p>
            </div>
            <div className='hr-slide-two mt-20 d-flex flex-column align-items-center '>
                <ul id="hrSlideContainer" className='hr-slide-ul d-flex flex-row justify-content-between align-self-center'>
                        {hrData.map((each)=>
                            <li key={each.id} id="hrChild" className='hr-slide-items m-r-40'>
                                {each.icon}
                                <h3 className='hr-slide-head mt-10 text-18 font-weight-600'>{each.title}</h3>
                                <p className='hr-slide-para p-size font-weight-400'>{each.content}</p>
                            </li>
                        )}
                </ul>
            </div>
            <div className="hr-slide-icons d-flex flex-row justify-content-between align-self-center">
                <div className="slide-icon-flat d-flex flex-row align-items-center p-l-15">
                    <div style={{backgroundColor:scrollLeft === 0?"#227AFF":"#D9D9D9"}} className="w-px-30 h-px-3 rounded-5 m-r-10"></div>
                    <div style={{backgroundColor:scrollRight === 0?"#227AFF":"#D9D9D9"}} className="w-px-30 h-px-3 rounded-5 slide-line"></div>
                </div>
                <div className="d-flex flex-row icon-con">
                   {shouldShowLeftArrow && <LeftArrow onClick={slideLeft} className='icon-left-arrows'/>}
                    {shouldShowRightArrow && <LeftArrow onClick={slideRight} className='icon-right-arrows' />}
                </div>
            </div>
        </motion.div>
    </motion.div>
  )
}

export default AttendanceSlide