import { useContext } from "react";
import { MenuContext } from "../../../app";
import "./index.scss"
import {motion} from "framer-motion"
import { Link } from "react-router-dom"


const MobBlogMenu = () => {

    const props = useContext(MenuContext)
    const {isOpen, setIsOpen, setIsProductOpen} = props

    interface PathProps {
        d?: string;
        variants: any;
        initial: boolean;
        animate: string;
        transition?: { duration: number };
    }

    const Path:React.FC<PathProps> = ({d, variants, initial, animate, transition }) => (
        <motion.path
            fill="transparent"
            strokeWidth="3"
            stroke="#ffff"
            strokeLinecap="round"
            variants={variants}
            initial={initial} 
            animate={animate}
            transition={transition}
            d={d}
        />
      );

    const myVar1 ={
        open:{
            d: "M 3 16.5 L 17 2.5",
        },
        closed:{
            d: "M 2 2.5 L 20 2.5",
        }
    }
    const myVar2 ={
        open:{
            opacity:0,
        },
        closed:{
            opacity:1,
        }
    }

    const myVar3 ={
        open:{
            d: "M 3 2.5 L 17 16.346",
        },
        closed:{
            d: "M 2 16.346 L 20 16.346",
        }
    }

    const toggleMenu = () =>{
        setIsOpen((prevIsOpen: boolean) => !prevIsOpen);
        setIsProductOpen(false)
    }

    return (
        <>
        <div className="mob-blog-menu fixed-bottom">
            <div className="d-flex flex-row justify-content-center align-items-center">
                <div className="primary-blog d-flex justify-content-center align-items-center">
                    <motion.svg className="menu-blog" onClick={toggleMenu} width="23" height="23" viewBox="0 0 23 23">
                        <Path 
                            variants={myVar1}
                            initial={false} 
                            animate={isOpen? "open":"closed"} 
                        />
                        <Path
                            d="M 2 9.423 L 20 9.423"
                            variants={myVar2}
                            initial={false}
                            animate={isOpen? "open":"closed"}
                            transition={{duration:0.1}}
                        />
                        <Path
                            variants={myVar3}
                            initial={false} 
                            animate={isOpen? "open":"closed"}
                        />
                    </motion.svg>
                </div>
                <div className="signup d-flex justify-content-center align-items-center">
                    <Link to="/sign-up" className="mob-get text-center w-45" onClick={() => setIsOpen(false)}>
                        Get Started
                    </Link> 
                </div>
            </div>
            
        </div>
        </>
    )
}
export default MobBlogMenu