import { Link } from "react-router-dom"
import { TailSpin } from "react-loader-spinner"
import { useState, useEffect, useContext } from "react"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2, child1, child2 } from "../../../../variants"
import { Helmet } from 'react-helmet-async'

import  DashBoard from "../../../../assets/logos/attendance-dashboard.svg"
import {ReactComponent as Left} from "../../../../assets/logos/logo-left.svg"
import {ReactComponent as Right} from "../../../../assets/logos/logo-right.svg"
import {ReactComponent as ButtonLogo} from "../../../../assets/logos/button-logo.svg"
import AutoPlay from '../../../layout/industry-slide'
import {data, settings} from "../../../landing-page/home"
import AttendanceMerits from "../attendance-merits"
import AttendanceSlide from "../attendance-manage-slide"
import SecureAttendanceManage from "../secure-attendance-manage"
import Faqs from "../../../landing-page/faqs"
import HrTasks from "../../hr-hub/hr-tasks"
import Modules from "../modules"
import SlideItem from "../../../landing-page/slide-items"
import { settings1, settings2 } from "../../../landing-page/leave"
import Card from "../../../landing-page/card"
import './index.scss'
import MobMenu from "../../../layout/mob-menu";
import { BaseBtn } from "../../../../styled-components";
import { MenuContext } from "../../../../app";


type FaqData = {
    faq:{
        question:string 
        answer:string 
        order: number
    }[]
}

const initialData = {
    faq:[]
}

const attendanceKeywords1 =[
    {id:"slide0", name:"Shift scheduling software"},
    {id:"slide1", name:"Attendance management software"},
    {id:"slide2", name:"Employee attendance tracking"},
    {id:"slide3", name:"Time and attendance system"},
    {id:"slide4", name:"Biometric attendance system"},
    {id:"slide5", name:"Overtime tracking software"},
    {id:"slide6", name:"Compensation management system"},
]

const attendanceKeywords2 =[
    {id:"slide0", name:"Remote work policies"},
    {id:"slide1", name:"Hybrid work environment"},
    {id:"slide2", name:"Attendance regularization"},
    {id:"slide3", name:"Payroll integration"},
    {id:"slide4", name:"Time off management"},
    {id:"slide5", name:"Employee scheduling solution"},
    {id:"slide6", name:"Workforce management software"},
    {id:"slide7", name:"HR attendance software"},
]

const AttendanceMain = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
      });


    const [faqs, setFaqs] = useState<FaqData>(initialData);
    const [isLoading, setIsLoading] = useState(true)

    const {isOn} = useContext(MenuContext)

    const getFaqs = async() => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BASE_URL}/faq?menu=attendance-management`;
        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const faqs = await response.json()
                setFaqs(faqs.data)
            }
        }
        catch(error:any){
            console.error(`error in fetching faqs: ${error.message}`)
        }
    }


    useEffect(() => {
        getFaqs()

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    const child2w = {
        visible: {
          x:0,
          y:0,
          rotate:0,
          transition: {
            duration: 4,
            type:'spring'
          }
        },
        hidden: {
          x:1700,
          y:-350,
          rotate:90,
        }
      }


  return (
    <>
    <Helmet>
        <title>Attendance Management | Walkzero Technologies Private Limited</title>
    </Helmet>
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrMain" className="hr-excellence d-flex flex-column position-relative bg-col-grey-green">
        <motion.div className="product-inner-cont d-flex flex-row justify-content-between align-items-center align-self-center">
            <motion.div variants={child1} className="step-into-card d-flex flex-column">
                <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>Effortless Attendance, Anywhere, Anytime.</p>
                <h3 className='product-heading col-container-font h1-size'>Empower Your Workforce with <span className='col-secondary'> Smart Management </span>Solutions</h3>
                <p className='font font-weight-3 m-b-20 p-size'>Simplify shift management, attendance tracking, and remote work policies for a more efficient, adaptable workforce.</p>
                <div className="m-r-20 position-relative mob-width z-index-1">
                    <Link to="/sign-up" className="decoration">
                    <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4">
                        <ButtonLogo className="me-1 mb-1"/> 
                        <span className="col-white font-bold text-14 font-weight-700 z-index-0">Sign up for Free</span>
                    </BaseBtn>
                    </Link>
                </div>
            </motion.div>
            <motion.div variants={child2} className="d-flex flex-column justify-content-center align-items-center position-relative second-s">
                <img src={DashBoard} className='attend-img' alt="calendar" />
                <div className="position-absolute bgnd-attendance z-index-0 rounded-circle">
                </div>
            </motion.div>
        </motion.div>
        <motion.div variants={child2w} initial="hidden" animate="visible" className="position-absolute left--150 bottom-60 p-0 z-index-0">
          <Left id="Leftw-a" style={{rotate:"50deg"}}/>
        </motion.div>
        <div className="custom-shape-divider-bottom-1715923248">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
            </svg>
        </div>
    </motion.div>
    <AutoPlay data={data} settings={settings} />
    <AttendanceSlide />
    <AttendanceMerits />
    <div className="position-relative d-block">
        <SecureAttendanceManage/>
    </div>
    {isLoading? <div className="align-self-center m-t-100 m-b-100"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>:<Faqs faqs={faqs} setFaqs={setFaqs} />}
    <HrTasks />
    <Modules />
    <div className="d-flex flex-column p-b-100 hr-product-slide">
        <SlideItem data={attendanceKeywords1} settings={settings1} width={{width:"70%"}}/>
        <SlideItem data={attendanceKeywords2} settings={settings2} width={{width:"50%"}}/>
    </div>
    <Card />
    <MobMenu/>
    </>
  )
}

export default AttendanceMain