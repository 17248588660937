import './index.scss'
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import { useState, useEffect } from 'react'
import Team from '../../../assets/videos/team.mp4'

const data = [
    {id:"t1", name:"Entre"},
    {id:"t2", name:"Tamil"},
  ]

const Head = () => {

    const [scrollPosition, setScrollPosition] = useState(0);
    const [list, setList] = useState(data)

    useEffect(() => {
        const interval = setInterval(() => {

          setScrollPosition(prevPosition => prevPosition - 30);
          setList(prevList => [prevList[1], prevList[0]])
        }, 2000);
    
        return () => clearInterval(interval);
      }, []);

    return(
        <>
        <div className='d-flex flex-column justify-content-center align-items-center position-relative about-us-gif'>
            <p className='col-quaternary font-weight-5 text-12 mb-1 text-uppercase text-center mob-text'>Together, We Thrive - For Ourselves, Our Communities, and Our Planet.</p>
            <h1 className="col-container-font h1-size text-center lh-38 hide-mb">Empowering the <span className="col-secondary">Next</span> Generation of </h1>
            <h1 className="col-container-font h1-size text-center show-mb">Empowering <br/>the <span className="col-secondary">Next</span> Generation of </h1>
            <h1 className="col-container-font h1-size text-center about-text hide-mb"><span style={{ transform: `translateY(${scrollPosition}px)`, marginRight:list[0].name === "Entre"?'2px':"0px"}} className="col-secondary scrolling-text">{list[0].name}</span>preneurs</h1>
            <h1 className="col-container-font h1-size text-center about-text show-mb"><span style={{ transform: `translateY(${scrollPosition}px)`, marginRight:list[0].name === "Entre"?'1px':"0px"}} className="col-secondary scrolling-text">{list[0].name}</span>preneurs</h1>
            <p className='font font-weight-3 p-size text-center hide-mb'>We're dedicated to helping new entrepreneurs unlock their potential and pave the way to success not just for themselves, but for the wider community and the planet.</p>
            <Star id="about-star" className='position-absolute z-index-1 star1' />
            <Star id="about-star" style={{width:20, height:20}} className='position-absolute star2 z-index-1' />
            <Star id="about-star" style={{width:20, height:20, rotate:"45deg"}} className='position-absolute top-percentage-40 star3 z-index-1' /> 
            <Star id="about-star" style={{width:15, height:15, rotate:"45deg"}} className='position-absolute top-percentage-45 star4 z-index-1' />
            <div className="custom-shape-divider-bottom-1714115409 hide-mb">
                <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
                    <path d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z" className="shape-fill"></path>
                </svg>
            </div>
            {<video autoPlay muted loop id="bgVideo" className='position-absolute z-index--1 w-100'>
                <source src={Team} type="video/mp4" />
            </video>}
        </div>
        </>
    )
}

export default Head