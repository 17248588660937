import './index.scss'
import Thiru from '../../../assets/images/thiruvalluvar.png'
import {useAnimation, motion} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { container2, child1, child2, child3 } from '../../../variants'

const Mentor = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    return (
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='d-flex flex-column align-items-center align-self-center mentor-cont p-b-80'>
            <p className="col-quaternary font-weight-5 text-12 mb-1 text-center text-uppercase">#Eternal Wisdom Tirukkural</p>
            <h1 className="col-container-font h2-size mb-0 text-center">Presenting Our <span className='col-secondary'>Esteemed Mentor</span></h1>
            <motion.div className='d-flex flex-row justify-content-center align-items-center kural-cont'>
                <motion.div variants={child1} className='font col-container-font text-14 font-weight-6'><span className='col-secondary text-18'>“ </span>தெய்வத்தான் ஆகா தெனினும் முயற்சிதன் <br /> மெய்வருத்தக் கூலி தரும்.<span className='col-secondary text-18'> ”</span></motion.div>
                <motion.div variants={child3} className='d-flex flex-row justify-content-center position-relative'>
                    <img src={Thiru} alt='thiruvalluvar' className='mentor-img'/>
                    <div className='position-absolute back'></div>
                </motion.div>
                <motion.div variants={child2} className='font col-container-font text-14'><span className='col-secondary text-18 font-weight-6'>“ </span>Even if ool or god doesn’t aid, perseverance will <br /> pay the wages for one’s efforts<span className='col-secondary text-18 font-weight-6'> ”</span></motion.div>
            </motion.div>
            <motion.div variants={child3} className='d-flex flex-row justify-content-center align-items-center mob-mentor'>
                <motion.div variants={child3} initial={{opacity:0, y:50}} animate={{opacity:1, y:0, transition:{duration:1}}} className='d-flex flex-row justify-content-center position-relative w-45'>
                        <img src={Thiru} alt='thiruvalluvar' className='mentor-img'/>
                        <div className='position-absolute back'></div>
                </motion.div>
                <motion.div variants={child3} className='d-flex flex-column align-items-start kural-section'>
                    <div className='font col-container-font mb-4 kural'><span className='col-secondary text-18 font-weight-6'>“ </span>தெய்வத்தான் ஆகா தெனினும் முயற்சிதன் <br /> மெய்வருத்தக் கூலி தரும்.<span className='col-secondary text-18 font-weight-6'> ”</span></div>
                    <div className='font col-container-font kural'><span className='col-secondary text-18 font-weight-6'>“ </span>Even if ool or god doesn’t aid, perseverance will <br /> pay the wages for one’s efforts<span className='col-secondary text-18 font-weight-6'> ”</span></div>
                </motion.div>
            </motion.div>
        </motion.div>
    )
}


export default Mentor