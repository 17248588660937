import './index.scss'
import {ReactComponent as LeftArrow} from "../../../assets/logos/left-slide.svg"
import { useState, useEffect } from "react"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2, child } from '../../../variants';

type journeyItems = {
    id: string
    title: string
    formatted_date: string
    description: string
    points: string[]
}

type JourneyProps ={
    journey:{
        id: string
        title: string
        formatted_date: string
        description: string 
        points: string[]
    }[]
}

const OurJourney = (props:JourneyProps) => {

    const {journey} = props

    const [scrollRight, setScrollRight] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        const container:any = document.getElementById('journeyContainer');
        const child:any = document.getElementById("childJourney");
        const scrollAmount:number = Math.floor(container?.offsetWidth / child?.offsetWidth)
        const right:number = journey.length - scrollAmount
        setScrollRight(right)

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
        
    }, [ctrls, inView]);


    const slideLeft = () => {
        const container:any = document.getElementById('journeyContainer');
        const childWidth = container.children[0].offsetWidth;

        setScrollLeft(prevScrollLeft => prevScrollLeft - 1);
        setScrollRight(prevScrollRight => prevScrollRight + 1);
       
        container.scrollLeft -= (childWidth + 20)
        
    }

    const slideRight = () => {
        const container:any = document.getElementById('journeyContainer');
        const childWidth = container.children[0].offsetWidth;

        setScrollRight(prevScrollRight => prevScrollRight - 1);
        setScrollLeft(prevScrollLeft => prevScrollLeft + 1);
       
        container.scrollLeft += (childWidth +  20)
    }

    const shouldShowLeftArrow = scrollLeft > 0;
    const shouldShowRightArrow = scrollRight > 0;

    return (
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='w-100 bg-col-shadow-color d-flex flex-column justify-content-center align-items-center m-t-100 m-b-100 max-h'>
            <motion.div variants={child} className='d-flex flex-row justify-content-between align-items-center align-self-center outer-journey-cont'>
                <div className='journey-cont'>
                    <p className='col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>The Walkzero Evolution</p>
                    <h2 className='col-container-font h2-size mb-0'>Our <span className='col-secondary'>Journey</span></h2>
                </div>
                <div className='d-flex flex-row justify-content-center align-items-center outer-journey-slide'>
                    {shouldShowLeftArrow && <LeftArrow style={{cursor:"pointer"}} className="m-r-20" onClick={slideLeft} />}
                    <div id="journeyContainer" className='journey-slide d-flex flex-row'>
                    {journey.map((each:journeyItems) => {
                        return <div id="childJourney" key={each.id} className="journey-slide-item">
                                    <p className="text-12 col-container-font mb-1">{each.formatted_date} <br /><span className='col-secondary'>{each.title}</span></p>
                                    <hr className="journey-line mt-0 mb-0" />
                                    <p className="text-10 font mt-1">{each.description}</p>
                                    <ul className='p-l-15'>
                                        {each.points.map((point, index) => {
                                            return <li key={index} className='text-10 font '><span className='font-weight-7'>{point}</span></li>
                                        })}
                                    </ul>
                                </div>
                    })}
                    </div>
                    {shouldShowRightArrow && <LeftArrow style={{rotate:"180deg", cursor:"pointer"}} onClick={slideRight} className="m-l-20" />}
                </div>
            </motion.div>
        </motion.div>
    )
}

export default OurJourney