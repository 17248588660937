import './index.scss'
import {ReactComponent as ThinQ24} from "../../../assets/logos/thinq24.svg"
import {ReactComponent as Circle} from "../../../assets/logos/circle.svg"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';
import { container2 } from '../../../variants';


const OurInvesters = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child1 = {
        visible:{
            opacity:1,
            x:0,
            transition:{
                duration:0.5, 
                type:"tween",
                ease:"easeIn",
            }
        },
        hidden:{
            opacity:0,
            x:-50
        }
    }

    const child2 = {
        visible:{
            opacity:1,
            x:0,
            transition:{
                duration:0.5, 
                type:"tween",
                ease:"easeIn",
            }
        },
        hidden:{
            opacity:0,
            x:50
        }
    }

    return (
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='invester-cont d-flex flex-row justify-content-between align-items-center align-self-center p-t-60 p-b-60 position-relative'>
            <motion.div variants={child1} className='our-invester-cont'>
                <p className="col-quaternary font-weight-5 text-12 mb-1 text-uppercase"> Being a Bootstrap Pioneer: Strength in Self-Sufficiency</p>
                <h1 className="col-container-font h2-size mb-0">Our <span className='col-secondary'>Investors</span></h1>
                <p className='font font-weight-3 p-size'>At Walkzero, we take pride in being a bootstrapped company. This financial strategy signifies not just our independence but also our commitment to growth and innovation on our own terms. Funded entirely by thinQ24 Innovations, our parent company, we harness the freedom to focus solely on developing products that truly resonate with our customers and disrupt the HR tech industry.</p>
                <p className='font font-weight-3 p-size hide-mb'>As we continue to grow and scale our operations with the robust backing of thinQ24 Innovations, we remain committed to pushing the boundaries of what's possible in HR technology. We are poised to enhance our research and development, expand our team, and continuously improve our offerings to meet and exceed the evolving needs of businesses around the world.</p>
            </motion.div>
            <motion.div variants={child2} className='investor d-flex flex-column align-items-center'>
                <ThinQ24 width={200} height={50} />
                <p className='font font-weight-3 text-11 text-center'>Software development Company that encourages thinking beyond limits, turning our customers' ideas into reality. We deliver tailored solutions for business growth and provide unwavering support for the success of our customers' brands. Founded in 2019 and based in Chennai, TN, India. Our expertise lies in dynamic IT Outsourcing, IT Staffing, and Software Development, providing comprehensive services across the USA, UK, and India.</p>
            </motion.div>
            <Circle className="position-absolute bottom--100 right-0" />
        </motion.div>
    )
}

export default OurInvesters