
type RichTextFieldProps = {
  richTextFieldContent:any
}

const RichTextFieldComponent = ({ richTextFieldContent }:RichTextFieldProps) => {
  
  const createMarkup = (htmlContent:any) => ({ __html: htmlContent });

  return (
    <div className="font-1">
      <div dangerouslySetInnerHTML={createMarkup(richTextFieldContent)} />
    </div>
  );
};

export default RichTextFieldComponent;