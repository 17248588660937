import "./index.scss"
import {ReactComponent as Attendance} from '../../../../assets/logos/attendance-management.svg'
import {ReactComponent as HrHub} from '../../../../assets/logos/hr-hub.svg'
import {ReactComponent as Geofencing} from '../../../../assets/logos/geo-fencing.svg'
import {ReactComponent as LeaveManagement} from '../../../../assets/logos/leave-management.svg'
import { FaArrowRight } from "react-icons/fa6";
import { useEffect } from "react"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container, child5} from "../../../../variants"
import { Link } from "react-router-dom"

const data =[
    {id:2, icon:<Attendance />, name:"Attendance Management", content:"Enable integrated system facilitating transparent and accurate attendance tracking.", isClicked: false, clr:"#12A8A8", link:"/attendance-management"},
    {id:8, icon:<HrHub />, name:"HR Hub", content:"Simplify and Strengthen Your HR Operation in a Single, Centralized Platform.", isClicked: false, clr:"#57B757", link:"/hr-hub"},
    {id:3, icon:<LeaveManagement />, name:"Leave Management", content:"Elevate Employee experience with user friendly self-service Solution.", isClicked: false, clr:"#B5B510", link:"/leave-management"},
    {id:9, icon:<Geofencing />, name:"Geo Fencing", content:"Leveraging location based HR Solution, eliminating manual process", isClicked: false, clr:"#FF5722", link:"/geo-fencing"},
]


const Modules = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    return (
        <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} className="d-flex flex-row justify-content-around ess-modules-cont m-t-60 m-b-20">
            {data.map(each => {
                return <motion.div variants={child5} key={each.id} className="modules-card d-flex flex-column justify-content-between rounded-16 p-3">
                    <div>
                        <span className="font-1 p-size font-weight-6">{each.icon} {each.name}</span>
                        <p className="text-10 font-weight-3 font-1 op">{each.content}</p>
                    </div>
                    <div className="d-between flex-row">
                        <Link to={each.link} style={{color:each.clr}} className="p-size font-1 decoration">Learn more</Link>
                        <Link to={each.link} style={{color:"#1F3549"}} className="decoration"><FaArrowRight /></Link>
                    </div>
                </motion.div>
            })}
        </motion.div>
    )
}


export default Modules