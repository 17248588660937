import { useState, useContext } from "react"
import { Link } from "react-router-dom"
import { MenuContext } from "../../../app"
import {motion} from "framer-motion"

import './index.scss'

import {ReactComponent as HrHub} from '../../../assets/logos/hr-hub.svg'
import {ReactComponent as Ess} from '../../../assets/logos/employee-self-service.svg'
// import {ReactComponent as SelfOnboarding} from '../../../assets/logos/self-onboarding.svg'
// import {ReactComponent as Eseparation} from '../../../assets/logos/e-separation.svg'
import {ReactComponent as LeaveManagement} from '../../../assets/logos/leave-management.svg'
// import {ReactComponent as PropertyManagement} from '../../../assets/logos/property-management.svg'
import {ReactComponent as Attendance} from '../../../assets/logos/attendance-management.svg'
// import {ReactComponent as Payroll} from '../../../assets/logos/payroll-menu.svg'
import {ReactComponent as Geofencing} from '../../../assets/logos/geo-fencing.svg'
import MobProducts from '../../layout/mob-products'

export const data1 = [
    {id:"pr1", name:"HR Products", isClicked:true, link:<MobProducts/>},
    // {id:"pr2", name:"R&D", isClicked:false},
]

export const data2 =[
    {id:'m1', icon:<HrHub />, name:"HR Hub", content:"Simplify and Strengthen Your HR Operation in a Single, Centralized Platform.", isClicked: false, to:'/hr-hub', clr:"#57B757", bg:"rgba(87, 183, 87, 0.1)" },
    {id:'m2', icon:<LeaveManagement />, name:"Leave Management", content:"Elevate Employee experience with user friendly self-service Solution.", isClicked: false, to:'/leave-management', clr:"#B5B510", bg:"#B5B5101A" },
    {id:'m3', icon:<Attendance />, name:"Attendance Management", content:"Enable integrated system facilitating transparent and accurate attendance tracking.", isClicked: false, to:'/attendance-management', clr:"#12A8A8", bg:"rgba(18, 168, 168, 0.1)"},
    {id:'m4', icon:<Geofencing />, name:"Geo Fencing", content:"Leveraging location based HR Solution, eliminating manual process.", isClicked: false, to:'/geo-fencing', clr:"#FF5722", bg:"#FEF4EA" },
    {id:'m5', icon:<Ess />, name:"Employee Self Service", content:"Tailor Your Policies and workflow for effortless time-off tracking and approvals.", isClicked: false, to:'/ess', clr:"#227AFF", bg:"#227AFF1A" },
    // {id:'m6', icon:<SelfOnboarding />, name:"Self Onboarding", content:"Provide a quick start for new hires with efficient onboarding process.", isClicked: false, to:'', clr:"#12A8A8", bg:"rgba(18, 168, 168, 0.1)" },
    // {id:'m7', icon:<Payroll />, name:"Payroll", content:"Compensation process in few clicks, minimizing errors and maximinzing efficiency.", isClicked: false, to:'', clr:"#066161", bg:"rgba(6, 97, 97, 0.1)" },
    // {id:'m4', icon:<Eseparation />, name:"E Separation", content:"Automated system eliminating the need for manual check-ins during the separation process.", isClicked: false, to:'', clr:"#066161", bg:"rgba(6, 97, 97, 0.1)" },
]

// const data3 = [
//     {id:'m6', icon:<PropertyManagement />, name:"Property Management", content:"Streamline and organize your property management with an intuitive and efficient solution", isClicked: false, to:'', clr:"#B5B510", bg:"rgba(181, 181, 16, 0.1)" },
// ]



const ProductMenu = () => {

    const props = useContext(MenuContext)
    const {setIsProductOpen, modules, setModules} = props
    const [product, setProduct] = useState(data1)

    const handleClick = (id:number) =>{
        setIsProductOpen(false)
        setModules((prevModules:any) => prevModules.map((each:any) => {
            if(each.id === id){
                return{...each, isClicked: !each.isClicked}
            }
            return {...each, isClicked: false}
        }))
    }

    const showProduct = (name:string) => {
        setProduct(prevProduct => prevProduct.map(each => {
            if(each.name === name){
                return {...each, isClicked: !each.isClicked}
            }
            return {...each, isClicked: false}
        }))

        if(name === "HR Products"){
            setModules(data2)
        }else{
            // setModules(data3)
        }
    }


  return (
    <div className='menu-parent d-flex flex-column align-self-center'>
        <div className="d-flex flex-row align-self-center w-100">
            <div className="side-menu">
                <ul className='d-flex flex-column align-items-start p-0'>
                    {product.map(each => {
                        return <li key={each.id} onClick={() => showProduct(each.name)} className={each.isClicked?'side-menu-list m-t-15 col-secondary':'side-menu-list m-t-15'}>{each.name}</li>
                    })}
                </ul>
            </div>
            <div className="product-menu d-between flex-row">
                <ul className="menu-data-list m-0 p-0">
                    {modules.map((each:any) => (
                        <Link key={each.id} to={each.to} style={{backgroundColor:each.isClicked?each.bg:"#ffffff"}} className="menu-item decoration rounded-2">
                            <motion.li whileHover={{backgroundColor:each.bg}} className="d-flex flex-column p-y-10 p-x-10 rounded-2" onClick={()=>handleClick(each.id)}>
                                <motion.p className='d-flex flex-row align-items-center m-t-0'>{each.icon}<motion.span whileHover={{color:each.clr}} className='m-l-10 menu-name'>{each.name}</motion.span></motion.p>
                                <p className="m-b-0 m-t-0">{each.content}</p>
                            </motion.li>
                        </Link>
                    ))}
                </ul>
            </div>
        </div>
    </div>
  )
}

export default ProductMenu