import { useContext, useEffect } from 'react'
import { MenuContext } from '../../../app'
import {motion} from "framer-motion"

import './index.scss'
import ProductMenu from '../product-menu'
import ProductMenuCard from '../product-menu-card'


const Menu = () => {

    const props = useContext(MenuContext)
    const {isProductOpen, setIsProductOpen} = props

    useEffect(() => {
        const handleResize = () => {
          if (window.innerWidth < 860) {
            setIsProductOpen(false)
          }
        };
    
        window.addEventListener('resize', handleResize);

        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <>
        <motion.div onMouseEnter={() => setIsProductOpen(true)} onMouseLeave={() => setIsProductOpen(false)} initial={{y:"-300vh"}} animate={{y:isProductOpen?0:"-300vh", transition:{ type:"spring", duration:1}}} className='d-flex flex-column justify-content-between product-menus-cont align-self-center bg-col-white menu-shad'>
            <ProductMenu />
            <ProductMenuCard />
        </motion.div>
        </> 
    )
}

export default Menu