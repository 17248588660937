import {ReactComponent as Login} from "../../assets/logos/walkzero-logIn.svg"
import {ReactComponent as Logo} from "../../assets/logos/logo.svg"
import {ReactComponent as W} from "../../assets/logos/w-login.svg"
import {ReactComponent as Right} from "../../assets/logos/login-right.svg"
import TextField from '@mui/material/TextField';
import {Link} from "react-router-dom"
import { useState, useEffect , useContext} from "react";

import CryptoJS from "crypto-js"

import './index.scss';
import { DOMAIN_LOGIN } from "../../graphql/queries";
import { MenuContext } from "../../app";


const LogIn = ()=> {
    
    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const [email, setEmail] = useState("")
    const [err, setErr] = useState("")
    const [isError, setIsError] = useState(false)
    const [isButton, setIsButton] = useState(true)
    const [showDomain, setShowDomain] = useState(false)
    const [domains, setDomains] = useState<any[]>([])
    const [selectedDomain, setSelectedDomain] = useState("")
    const [domainId, setDomainId] = useState("")

    const {isOn} = useContext(MenuContext)

    interface EmailInput{
        email:string
    }

    useEffect(() => {
        if (domains.length === 1) {
            setShowDomain(false)
            const token = encodeURIComponent(
                CryptoJS.AES.encrypt(email+";"+domainId,`${process.env.REACT_APP_SECRETKEY}`).toString()
            );
            window.location.href = `${domains[0].url}/login?token=${token}`;
        } 
        else if (domains.length > 1) {
            setShowDomain(true)
        }
    }, [domains, email, domainId])

    const checkDomain = async(input:EmailInput) =>{
        const url = `${process.env.REACT_APP_WZ_URL}`
        const response = await fetch(url,{
            method: 'POST', 
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                query: DOMAIN_LOGIN,
                variables: {input},
            })
        })
        if(response.ok){
            const res = await response.json();
            const {data, errors} = res
            if(data){
                setDomains(data.preLogin.domains)
                setDomainId(data.preLogin.id)
            }
            if(errors){
                setIsError(true)
                if(errors[0].email){
                    setErr(errors[0].email[0])
                }else{
                    setErr(errors[0].Enter[0])
                }
            }
        }
    }
   
    const workEmail = (event:React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        const mail = event.target.value

        if (mail === ""){
            setIsButton(true)
            setIsError(true)
            setErr("This field is required.")
        }
        else if(pattern.test(mail)){
            setIsButton(false)
            setIsError(false)
        }
        else{
            setIsButton(true)
            setIsError(true)
            setErr("Enter a valid email address.")
        }
    }
    

    const logInSelected:React.FormEventHandler<HTMLFormElement> =(event)=>{
        event.preventDefault();

        if (selectedDomain){
            const token = encodeURIComponent(CryptoJS.AES.encrypt(email +";" + domainId, `${process.env.REACT_APP_SECRETKEY}`).toString())
            window.location.href = selectedDomain +`/login?token=${token}`;
        }
        
    }

    const logIn:React.FormEventHandler<HTMLFormElement> =(events)=>{
        events.preventDefault();
        if(isError){
            setShowDomain(false)
        }
        else if(!domains){
            const input = {"email":email}
            checkDomain(input)
        }
        else{
            setShowDomain(false)
            const input = {"email":email}
            checkDomain(input)
        }    
    }
                    
    const handleOptions = (event:any) =>{
        setSelectedDomain(event.target.value)
    }

    // const token = "U2FsdGVkX18CsqhSyRi7AnYg%2FBAS7DiwnHsg66%2FZatw%2BWI%2FrOjsox%2FvIJ%2F4q9zqLnuQzmUa88COSNFEkVg3Sig%3D%3D"
    // console.log(token)
    // console.log(`${process.env.REACT_APP_SECRETKEY}`)
    // const decrypt = decodeURIComponent(token)
    // console.log(decrypt)
    // const bytes = CryptoJS.AES.decrypt(decrypt,`${process.env.REACT_APP_SECRETKEY}`)
    // const text = bytes.toString(CryptoJS.enc.Utf8)
    // console.log(showDomain)
    
    return(
        <>
        {showDomain && domains.length > 1?
        <div className="login-top d-flex flex-column justify-content-center align-items-center position-relative">
            <p className="text-20 col-primary font-weight-6"><span className="col-secondary">Login</span> or create a <span className="col-secondary">new</span> account</p>
            <form onSubmit={logInSelected} className="login-form" noValidate>
                <div className="d-flex flex-column justify-content-center align-items-center"><Logo/></div>
                <p className="text-center text-13 name">Login as {email} <span className="col-secondary change" onClick={()=>{setShowDomain(false); setDomains([]); setDomainId(""); setEmail(""); setIsButton(true); setSelectedDomain("")}}>change</span></p>
                <div className="d-flex flex-row justify-content-between align-items-center mb-10">
                    <hr className="line-log w-35" />
                    <p className="text-12 text-center p-0 m-0 continue">Continue with</p>
                    <hr className="line-log w-35" />
                </div>
                <ul className="p-0">
                {domains.map((each:any, i:any)=>
                    <li className="domain-list" key={i}><label htmlFor={each['url']}><input className="m-r-5 radio" type="radio" value={each['url']} name="Domain" checked={selectedDomain === each['url']} onChange={handleOptions} id={each['url']}/>{each['url']}</label></li>
                )}
                </ul>
            <button type="submit" disabled={isButton} style={{backgroundColor:isOn?isButton?"#1A2D483A":"#1A2D48":isButton?"#F456003A":"#F45600"}} className="login-button w-100 text-14 ">Continue</button>
            <p className="text-center text-12 support">Having problem in login please reach out <span className="col-secondary"><a href="mailto:support@walkzero.com?Subject=Hello%20" target="_top" className="col-secondary change">support@walkzero.com</a></span></p>
            </form>
            <Right className="position-absolute top-0 right-0"/>
            <Login className="position-absolute w-100"/>
        </div>
        :
        <div className="login-top d-flex flex-column justify-content-center align-items-center position-relative">
            <p className="text-20 col-primary font-weight-6"><span className="col-secondary">Login</span> or create a <span className="col-secondary">new</span> account</p>
            <form onSubmit={logIn} className="login-form" noValidate>
                <div className="d-flex flex-column justify-content-center align-items-center"><Logo/></div>
                <p className="text-center text-13 name">Login or Create a new Account</p>
                <TextField required className="inputs w-100" margin="normal" label="Work Email" variant="outlined" size="small" type="email" value={email} onChange={workEmail} error={isError?true:false}/>
                {isError && <span style={{opacity:isError?1:0}} className="d-flex text-12 font-1 align-self-start col-error msg-height-login m-l-10 m-t--5">{err}</span>}
                <button type="submit" className="login-button w-100 text-14" disabled={isButton} style={{backgroundColor:isOn?isButton?"#1A2D483A":"#1A2D48":isButton?"#F456003A":"#F45600"}} >Continue</button>
                <p className="text-center text-12 support">Having problem in login please reach out <span className="col-secondary"><a href="mailto:support@walkzero.com?Subject=Hello%20" target="_top" className="col-secondary change">support@walkzero.com</a></span></p>
                <div className="d-flex flex-row justify-content-between align-items-center mb-10">                        
                    <hr className="line-log w-23" />
                    <p className="text-12 text-center p-0 m-0 continue">Or Continue with Sign up</p>
                    <hr className="line-log w-23" />
                </div>
                <Link to="/sign-up" className="d-flex justify-content-center align-items-center text-14 link-span"><span className="w-logo"><W/></span>Create a Account&nbsp;<span className="col-secondary">Sign up</span></Link>     
            </form>
            <Right className="position-absolute top-0 right-0"/>
            <Login className="position-absolute w-100"/>   
        </div>
        }
        </>
    )
}
export default LogIn