import './index.scss'
import {ReactComponent as Vision} from "../../../assets/logos/vision.svg"
import {ReactComponent as Mission} from "../../../assets/logos/mission.svg"
import {ReactComponent as Planet1} from "../../../assets/logos/planet1.svg"
import {ReactComponent as Planet2} from "../../../assets/logos/planet2.svg"
import {ReactComponent as Planet3} from "../../../assets/logos/planet3.svg"
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import {useAnimation, motion} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { container2 } from '../../../variants'

const OurVision = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child = {
        visible:{
            y:0,
            opacity:1,
            transition:{
                duration:2, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:-50,
            opacity:0
        }
    }

    const child2 = {
        visible:{
            y:0,
            opacity:1,
            transition:{
                duration:2, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:50,
            opacity:0
        }
    }

    const child3 = {
        visible:{
            y:0,
            transition:{
                duration:2, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:200,
        }
    }

    const child4 = {
        visible:{
            y:0,
            transition:{
                duration:2, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:-200,
        }
    }


    return(
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='d-flex flex-row justify-content-between align-items-center align-self-center vision-cont p-t-20'>
            <motion.div variants={child} className='d-flex flex-column vision'>
                <div className='d-flex flex-column our-vision-cont'>
                    <p className="col-quaternary font-weight-5 text-12 mb-1 text-uppercase">Indian roots, Global vision</p>
                    <h1 className="col-container-font h2-size mb-0">Our <span className='col-secondary'>Vision</span></h1>
                    <p className='font font-weight-3 p-size'>At Walkzero, we are driven to lead the digital transformation in business management for small and medium enterprises, not only enhancing the day-to-day experiences of employees but also advancing significant growth and innovation across various industries. With our roots firmly planted in Chennai, we aim to extend our reach from the heart of Tamil Nadu to the tech hubs of Silicon Valley.</p>
                    <p className='font font-weight-3 p-size'>We are committed to contributing to the state of Tamil Nadu's ambitious goal of achieving a $1 trillion economy. Our vision encapsulates a journey of impactful branding and global influence, where our solutions become cornerstones of business efficiency worldwide.</p>
                </div>
                <Vision className='our-vision-img align-self-center' />
            </motion.div>
            <div className='d-flex flex-column justify-content-center align-items-center stick'>
                <motion.div variants={child3} className='rounded-25 h-px-15 w-px-15 bg-col-dark-sandle'></motion.div>
                <motion.div className='vertical-line bg-col-dark-sandle'></motion.div>
                <motion.div variants={child4} className='rounded-25 h-px-15 w-px-15 bg-col-dark-sandle'></motion.div>
            </div>
            <motion.div variants={child2} className='d-flex flex-column mission position-relative'>
                <Mission className='our-mission-img align-self-center' />
                <div className='d-flex flex-column our-mission-cont'>
                    <p className="col-quaternary font-weight-5 text-12 mb-1 text-uppercase">Shaping the Future of Work</p>
                    <h1 className="col-container-font h2-size mb-0">Our <span className='col-secondary'>Mission</span></h1>
                    <p className='font font-weight-3 p-size'>At Walkzero, we are dedicated to transforming the way businesses operate. Our mission is to empower 10,000 businesses from small startups to large enterprises and positively impact the lives of 1 million employees worldwide with our cutting-edge business management solutions by 2030.</p>
                    <p className='font font-weight-3 p-size'>We recognize that modern organizations confront rapidly evolving demands. That’s why our software is meticulously engineered to be affordable, scalable, and user-friendly, ensuring that companies of all sizes can adapt swiftly and succeed in a dynamic business landscape.</p>
                </div>
                <Planet1 className="position-absolute planet1"/>
                <Planet2 className="position-absolute planet2"/>
                <Planet3 className="position-absolute planet3"/>
                <Star width={18} style={{rotate:"40deg"}} className="position-absolute planet4"/>
            </motion.div>
        </motion.div>
    )
}

export default OurVision