import React from "react";
import Slider from "react-slick";
import { AutoPlay2Props } from "../../demo/demo-slide";

import "./index.scss"

function AutoPlay(props:AutoPlay2Props) {

  const {data, settings} = props

  return (
    <div id="autoPlay" className="slider-container m-t-40 slide-size align-self-center mask">
      <Slider {...settings}>
        {data.map(each => {
          return <span key={each.id} className="d-flex flex-row justify-content-center align-items-center mb-3">{each.icon} {each.name}</span>
        })}
      </Slider>
    </div>
  );
}

export default AutoPlay