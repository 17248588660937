import './index.scss'
import { MdOutlineKeyboardArrowLeft, MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState, useEffect } from 'react';
import { CultureProps } from '../our-values'
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { child } from '../../../variants';

type CultureItem = {
    id: string;
    photo: string;
    order: number;
}

const OurCulture = (props:CultureProps) => {

    const {culture} = props

    const [scrollRight, setScrollRight] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [centerIndex, setCenterIndex] = useState(0);

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        const updateDimensions = () => {
            const scrollingContainer:any = document.getElementById("cultureContainer");
            const child:any = document.getElementById("childCult");
            const scrollAmount = Math.floor(scrollingContainer.offsetWidth / (child.offsetWidth))
            const center = Math.ceil(scrollAmount / 2)
            setCenterIndex(center - 1)
            const right = culture.length / scrollAmount
            setScrollRight(right)
        }

        updateDimensions();

        window.addEventListener("resize", updateDimensions);

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
    }, [culture, ctrls, inView])

    const slideRight = () => {

        const scrollingContainer:any = document.getElementById("cultureContainer");
        const child:any = document.getElementById("childCult");
        const scrollAmount = Math.floor(scrollingContainer.offsetWidth / (child.offsetWidth))
       
        setScrollRight(prevScrollRight => prevScrollRight - 1);
        setScrollLeft(prevScrollLeft => prevScrollLeft + 1);
    
        setCenterIndex(prevCenterIndex => prevCenterIndex + scrollAmount)
        
        scrollingContainer.scrollLeft += (child.offsetWidth * scrollAmount) + (scrollAmount * 40)

    }

    const slideLeft = () => {

        const scrollingContainer:any = document.getElementById("cultureContainer");
        const child:any = document.getElementById("childCult");
        const scrollAmount = Math.floor(scrollingContainer.offsetWidth / (child.offsetWidth))

        setScrollLeft(prevScrollLeft => prevScrollLeft - 1);
        setScrollRight(prevScrollRight => prevScrollRight + 1);

        setCenterIndex(prevCenterIndex => prevCenterIndex - scrollAmount)
       
        scrollingContainer.scrollLeft -= (child.offsetWidth) * scrollAmount + (scrollAmount * 40)
       
    }

    const shouldShowLeftArrow = scrollLeft > 0;
    const shouldShowRightArrow = scrollRight > 1;

    return(
        <motion.div ref={ref} initial="hidden" animate={ctrls} variants={child} className='d-flex flex-column justify-content-start align-items-center align-self-center culture-cont m-t-60'>
            <p className="col-quaternary font-weight-5 text-12 mb-1 text-center text-uppercase">Innovate Boldly, Live Authentically</p>
            <h1 className="col-container-font h2-size mb-0">Our <span className='col-secondary'>Culture</span></h1>
            <p className='font font-weight-3 p-size text-center hide-mb'>At Walkzero, our culture is built on a foundation of innovation, integrity, and inclusivity. We are a team that values agility and ownership, empowering every member to lead and innovate within their roles. We prioritize rapid adaptation to both market demands and internal challenges, maintaining a forefront position in our industry.</p>
            <p className='font font-weight-3 p-size text-center hide-mb'>Join us as we pave the way for a more efficient, productive, and compliant global workplace. Together, we can craft environments where businesses not only thrive but also where every employee has the opportunity to flourish. Let's transform the future of work, from Chennai to the world.</p>
            <p className='font font-weight-3 p-size text-center show-mb'>At Walkzero, our culture is built on a foundation of innovation, integrity, and inclusivity. We are a team that values agility and ownership, empowering every member to lead and innovate within their roles. We prioritize rapid adaptation to both market demands and internal challenges, maintaining a forefront position in our industry. Let's transform the future of work, from Chennai to the world.</p>
            {/* <CenterMode data={data} /> */}
            <div className='w-100 d-flex flex-row justify-content-between align-items-center align-self-center m-t-20'>
                <MdOutlineKeyboardArrowLeft style={{visibility:shouldShowLeftArrow?"visible":"hidden", cursor:"pointer"}} className='text-25 m-r-20 z-index-5' onClick={slideLeft}/>
                <div id="cultureContainer" className='culture-slide-cont d-flex flex-row justify-content-start align-items-center align-self-center'>
                    {culture.map((each:CultureItem, index)=> {
                        const isCenter = index === centerIndex; 
                        const imageSizeClass = isCenter ? 'cult-img center-img m-r-40' : 'cult-img m-r-40';
                        return <img id="childCult" key={each.id} className={imageSizeClass} src={each.photo} alt="culture" />
                    })}
                </div>
                <MdOutlineKeyboardArrowRight style={{visibility:shouldShowRightArrow?"visible":"hidden", cursor:"pointer"}} className='text-25' onClick={slideRight} />
            </div>
        </motion.div>
    ) 
}

export default OurCulture