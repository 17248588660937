import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom'
import { useState, createContext } from 'react';
import { HelmetProvider, Helmet } from 'react-helmet-async';

import ScrollToTop from './scroll-to-top';
import Navbar from './components/layout/nav-bar';
import Footer from './components/layout/footer';
import MobileNav from './components/layout/mobile-nav';
import ScrollToTopButton from './components/layout/back-to-top';
import Main from './components/demo/main';
import Home from './components/landing-page/home';
import About from './components/about-us/about';
import Blog from './components/blog/main-blog';
import BlogDetails from './components/blog/blog-details';
import Menu from './components/product-menu/menu';
import HrHub from './components/product/hr-hub/hr-hub-main';
import LeaveMain from './components/product/leave-management/leave-main';
import AttendanceMain from './components/product/attendance-management/attendance-main';
import GeoMain from './components/product/geo-fencing/geo-main';
import "./style.scss";
import {data2} from "./components/product-menu/product-menu";
import MobProducts from './components/layout/mob-products';
import LogIn from './components/login';
import Master from './components/signup/master';
import AccountVerified from './components/signup/account-verified';
import ActivateAccount from './components/signup/activate-account';
import NotFound from './components/not-found';
import EssMain from './components/product/employee-self-service/ess-main';
import PrivacyPolicy from './components/layout/privacy-policy';

export const MenuContext = createContext<any>(null);

export type BlogList = {
  count: number 
  next: string
  previous: string 
  results:{
   id: string 
   title: string 
   short_description: string
   photo: string
   slug: string
   category:[]
  }[],
  categories:{
    category__name: string 
    category__id: string 
    category__is_clicked: boolean
  }[]
}


const initialData = {
  count: 0,
  next: '',
  previous: '',
  results: [],
  categories: []
}

const initialAboutusData = {
  employee: [],
  journey: [],
  story: [],
  culture: []
}


function App() {

  const [isOpen, setIsOpen] = useState(false);
  const [isOn, setIsOn] = useState(false);
  const [searchInput, setSearchInput] = useState('')
  const [blogList, setBlogList] = useState<BlogList>(initialData)
  const [isLoading, setIsLoading] = useState(true)
  const [isProductOpen, setIsProductOpen] = useState(false)
  const [mobProduct, setMobProduct] = useState(false)
  const [aboutUsData, setAboutUsData] = useState(initialAboutusData)
  const [modules, setModules] = useState(data2);
  const [isMobProductOpen, setIsMobProductOpen] = useState(false)

  return (
    <HelmetProvider>
        <MenuContext.Provider value={{isOpen, setIsOpen, isOn, setIsOn, searchInput, setSearchInput, blogList, setBlogList, isLoading, setIsLoading, isProductOpen, setIsProductOpen, modules, setModules, aboutUsData, setAboutUsData, mobProduct, setMobProduct, isMobProductOpen, setIsMobProductOpen}}>
          <Router>
            <Helmet>
              <meta property="og:title" content="Walkzero - India’s Unified Platform: The best HR and Payroll software" />
              <meta property="og:description" content="India’s Unified Platform: The best HR and Payroll software for seamless employee management. Empower your business with simplified attendance tracking, leave management, and self-service employee information access. Optimize your HR operations and embrace the future of Human Resource Management (HRMS) software for enhanced human capital management (HCM)." />
              <meta className="og-image" property="og:image" content="https://www.walkzero.com/static/wz-logo.png" />  
              <meta property="og:url" content="https://www.walkzero.com/" />
            </Helmet>
            <ScrollToTop />
            <ScrollToTopButton />
            <Navbar />
            <MobileNav />
            <Menu />
            <MobProducts/>
            <Routes>
                <Route path="/book-a-demo" element={<Main />} />
                <Route path="/sign-up" element={<Master />} />
                <Route path="/about-us" element={<About />} />
                <Route path="/blogs" element={<Blog />} />
                <Route path="/blog/:slug" element={<BlogDetails />} />
                <Route path="/hr-hub" element={<HrHub />} />
                <Route path="/leave-management" element={<LeaveMain />} />
                <Route path="/attendance-management" element={<AttendanceMain />} />
                <Route path="/geo-fencing" element={<GeoMain />} />
                <Route path="/ess" element={<EssMain/>} />
                <Route path="/hr-products" element={<MobProducts/>} />
                <Route path="/login" element={<LogIn/>} />
                <Route path="/account/verify/:id" element={<AccountVerified />} />
                <Route path="/account/activate/:id" element={<ActivateAccount />} />
                <Route path="/" element={<Home />} />
                <Route path="*" element={<NotFound />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy/>} />
            </Routes>
            <Footer />
          </Router>
        </MenuContext.Provider>
    </HelmetProvider>
  );
}

export default App;

