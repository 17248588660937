import './index.scss'
import {MdOutlineKeyboardArrowRight } from "react-icons/md";
import { useState, useEffect, useContext } from 'react';
import {ReactComponent as ButtonArrow} from "../../../assets/logos/button-arrow.svg"
import { MenuContext } from '../../../app';


const CategorySlide = (props:any) => {

    const {isOn} = useContext(MenuContext)

    const {categories, filterBlogsByCategory} = props
    const [scrollRight, setScrollRight] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        const updateDimensions = () => {
            const scrollingContainer:any = document.getElementById("categoryContainer");
            const child:any = document.getElementById("categoryChild");
            const scrollAmount = Math.floor(scrollingContainer.offsetWidth / (child.offsetWidth))
            const right = categories.length - scrollAmount
            setScrollRight(right)
        }

        updateDimensions();

        window.addEventListener("resize", updateDimensions);

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
        
    }, [categories])

    const slideRight = () => {

        const scrollingContainer:any = document.getElementById("categoryContainer");
        const child:any = document.getElementById("categoryChild");
       
        setScrollRight(prevScrollRight => prevScrollRight - 1);
        setScrollLeft(prevScrollLeft => prevScrollLeft + 1);
    
        
        scrollingContainer.scrollLeft += (child.offsetWidth + 40)

    }

    const slideLeft = () => {

        const scrollingContainer:any = document.getElementById("categoryContainer");
        const child:any = document.getElementById("categoryChild");

        setScrollLeft(prevScrollLeft => prevScrollLeft - 1);
        setScrollRight(prevScrollRight => prevScrollRight + 1);

       
        scrollingContainer.scrollLeft -= (child.offsetWidth + 40)
       
    }

    const shouldShowLeftArrow = scrollLeft > 0;
    const shouldShowRightArrow = scrollRight > 0;

    return(
        <>
        <div className='d-flex flex-row justify-content-center align-items-center align-self-center w-100'>
            {shouldShowLeftArrow && <ButtonArrow style={{rotate:"180deg"}} onClick={slideLeft} />}
            <div id="categoryContainer" className='w-90 d-flex flex-row justify-content-start align-items-center align-self-center'>
                {categories.map((each:any) => {
                    return <div onClick={() => {filterBlogsByCategory(each.category__id)}} style={{backgroundColor:isOn?each.category__is_clicked? "#1A2D48":"#E8EAED":each.category__is_clicked? "#F45600":"#E8EAED", color:each.category__is_clicked? "#ffffff":"#1F3549"}} id="categoryChild" key={each.category__id} className='d-flex flex-row justify-content-between align-items-center rounded-3 category-box1'>
                        <span className='text-12 font-weight-6 font-1'>{each.category__name}</span>
                        <MdOutlineKeyboardArrowRight />
                    </div>
                })}
            </div>
            {shouldShowRightArrow && <ButtonArrow onClick={slideRight}/>}
        </div>
        </>
    )
}

export default CategorySlide