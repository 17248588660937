import "./index.scss"
import {motion, useAnimation} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { Round, BaseSvg } from "../../../styled-components";
import { MenuContext } from "../../../app"
import { useContext } from "react"

type PlatformItemProps = {
    item:{
        id:string
        icon: JSX.Element
        name: string 
        content: string 
        isClicked: boolean
    }
    clickFeature: (id:string) => void
}

const PlatformItem = (props:PlatformItemProps) => {

    const {isOn} = useContext(MenuContext);

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.5,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    const {item, clickFeature} = props 
    const {id, icon, name, content, isClicked} = item

    const click = () => {
        clickFeature(id)
    }

    const container = {
        hidden:{
            opacity:0
        },
        visible: (i = 1) => ({
            opacity:1,
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * i,
            }
        }),
    }

    const child = {
        hidden:{
            opacity:0,
            y:20,
            transition:{
                ease: [0.2, 0.65, 0.3, 0.9],
                duration: 0.3,
            }
        },
        visible:{
            opacity:1,
            y:0,
            transition:{
                type:"spring",
                damping:10,
                stiffness:100,
                ease: [0.2, 0.65, 0.3, 0.9],
                duration: 0.8,
            }
        }
    }


    return(
        <motion.li ref={ref} variants={container} initial="hidden" animate={ctrls} className="platform-list" onClick={click}>
            <Round isOn={isOn} as={motion.div} variants={child} className="round">
                <BaseSvg isOn={isOn} >{icon}</BaseSvg>
            </Round>
            <motion.div variants={child} className="titles">
                <motion.p className={isClicked?"platform-title":"platform-title-normal"}>{name}</motion.p>
                {isClicked && <p className="title-content hide-content">{content}</p>}
            </motion.div>
        </motion.li>
    )
}

export default PlatformItem


