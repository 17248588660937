import './index.scss'
import {ReactComponent as ButtonLogo} from "../../../assets/logos/button-logo.svg"
import {ReactComponent as CreditCard} from "../../../assets/logos/credit-card.svg"
import {ReactComponent as UX} from "../../../assets/logos/ux-design.svg"
import { Link } from 'react-router-dom'
import { BaseBtn, DemoBtn } from "../../../styled-components"
import { MenuContext } from "../../../app"
import { useContext } from "react"


const Card = () => {

    const props = useContext(MenuContext);
    const {isOn} = props

    return(
        <div className='container m-b--30 z-index-2 yellow-card'>
            <div className='d-flex flex-row justify-content-between align-items-center bg-col-container-bg rounded-21 p-r-30 p-l-30 p-t-30 p-b-30 w-100 footer-card-cont'>
                <div className='card-1'>
                    <p className='col-quaternary font-weight-6 text-12 m-0 mb-2 text-uppercase'>A cloud HRMS platform</p>
                    <h3 className='col-container-font font-weight-7 h2-size cent1'>Transform Your HR  Management Today with <span className='col-secondary'>Walkzero</span></h3>
                    <p className='font font-weight-3 text-14'>We're not just a provider; we're your trusted HR tech partner, committed to constant innovation and ensuring mutual success together.</p>
                </div>
                <div className='card-2'>
                    <div className="d-flex flex-row justify-content-center align-items-center w-100 btn-cont">
                        <div className="m-r-20 position-relative mob-width">
                            <Link to="/sign-up" className="decoration">
                            <BaseBtn isOn={isOn} className="position-relative border-0 p-y-10 rounded-10 d-flex flex-row justify-content-center align-items-center w-100 btn-4">
                                <ButtonLogo className="me-1 mb-1" /> 
                                <span className="col-white font-bold text-14 font-weight-700 z-index-0">Sign up for Free</span>
                            </BaseBtn>
                            </Link>
                        </div>
                        <div className="mob-width">
                            <Link to='/book-a-demo' className="demo">
                                <DemoBtn isOn={isOn} className="rounded-10 font-bold text-14 font-weight-700 book-demo-btn w-100 p-y-10 btn-4-alternate">Book a Demo</DemoBtn>
                            </Link>
                        </div>
                    </div>
                    <div className="d-flex flex-row justify-content-center align-items-center pt-2 w-100 credit-cont">
                        <div className="font-11">
                            <CreditCard className="me-2"/>
                            <span className="font">No credit card required</span>
                        </div>
                        <div className="font-11 m-l-15 best">
                            <UX />
                            <span className="mt-2 font">India’s best-in-class user experience</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Card