import './index.scss'
import {useAnimation, motion} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { container2 } from '../../../variants';

type StoryProps = {
    story:{
        photo: string
    }
}
const OurStory = (props:StoryProps) => {

    const {story} = props

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    const child = {
        visible:{
            y:0,
            x:0,
            opacity:1,
            transition:{
                duration:1, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:-50,
            x:-50,
            opacity:0
        }
    }

    const child2 = {
        visible:{
            y:0,
            opacity:1,
            transition:{
                duration:1, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:-10,
            opacity:0
        }
    }


    return(
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='d-flex flex-row justify-content-between align-items-center story-cont'>
            <motion.img variants={child} src={story.photo} alt='our-story' className='our-story-img' />
            <motion.div variants={child2} className='d-flex flex-column our-story-cont'>
                <p className="col-quaternary font-weight-5 text-12 mb-1 text-uppercase">The Making of Walkzero</p>
                <h1 className="col-container-font h2-size mb-0">Building Better Workplaces: Our <span className='col-secondary'>Story</span></h1>
                <p className='font font-weight-3 p-size'>In our early career days at large corporations, We were impressed by how specialized software streamlined operations and boosted efficiency. However, it struck me — such advanced tools were often beyond the reach of smaller startups and young entrepreneurs constrained by budget. This gap in accessibility sparked a question: Could there be a way to bring this level of sophistication to smaller ventures without the hefty price tag?</p>
                <p className='font font-weight-3 p-size'>Driven by this vision, We embarked on our entrepreneurial journey. We tried various products available in the market to manage my startup, but none seemed to fit just right. They lacked the depth and customization that we had seen in the solutions used by top-tier companies. This gap between what was available and what could be inspired us to take action.</p>
                <p className='font font-weight-3 p-size'>That’s when Walkzero was born. Initially, it was a solution crafted out of necessity to address our own operational challenges. But soon, we realized many other entrepreneurs were in the same boat — needing better tools to help them concentrate on what really matters: growing their businesses and innovating.</p>
                <p className='font font-weight-3 p-size hide-mb'>Join us as we continue to innovate and provide tools that not only meet expectations but exceed them, ensuring every small, medium & big business has the opportunity to thrive.</p>
            </motion.div>
        </motion.div>
    )
}

export default OurStory