import './index.scss'
import Form from '../form'
import AutoPlay2 from '../demo-slide'
import {Link} from "react-router-dom"
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import AnimatedNumber from '../../animation/number'
import {ReactComponent as Education} from "../../../assets/logos/education.svg"
import {ReactComponent as Healthcare} from "../../../assets/logos/health-care.svg"
import {ReactComponent as Crane} from "../../../assets/logos/crane.svg"
import {ReactComponent as Clean} from "../../../assets/logos/clean.svg"
import {ReactComponent as Bus} from "../../../assets/logos/bus.svg"
import {ReactComponent as Food} from "../../../assets/logos/food.svg"
import {ReactComponent as Media} from "../../../assets/logos/media.svg"
import {ReactComponent as Flight} from "../../../assets/logos/flight.svg"
import {ReactComponent as Shopping} from "../../../assets/logos/shopping.svg"
import {ReactComponent as Financial} from "../../../assets/logos/financial.svg"
import {ReactComponent as Computer} from "../../../assets/logos/computer.svg"
import {ReactComponent as Shape1} from "../../../assets/logos/shape.svg"
import {ReactComponent as Shape2} from "../../../assets/logos/shape-2.svg"
import {ReactComponent as Quotes} from "../../../assets/logos/quotes.svg"
import Avatar from "../../../assets/images/avatar.png"
import { Helmet } from 'react-helmet-async'

const data = [
    {id:'field1', icon:<Education className="mr" />, name:'Education'},
    {id:'field2', icon:<Healthcare className="mr"/>, name:'Healthcare'},
    {id:'field3', icon:<Bus className="mr"/>, name:'Automobile'},
    {id:'field4', icon:<Crane className="mr"/>, name:'Manufacturing'},
    {id:'field5', icon:<Food className="mr"/>, name:'Food'},
    {id:'field6', icon:<Clean className="mr"/>, name:'Textiles'},
    {id:'field7', icon:<Computer className="mr"/>, name:'Information\u00A0Technology'},
    {id:'field8', icon:<Financial className="mr"/>, name:'Financial\u00A0Services'},
    {id:'field9', icon:<Shopping className="mr"/>, name:'FMCG'},
    {id:'field10', icon:<Flight className="mr"/>, name:'Transportation/Logistics'},
    {id:'field11', icon:<Media className="mr"/>, name:'Media'},
]

const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    speed: 3000,
    autoplaySpeed:0,
    cssEase: "linear",
    centerMode:true,
    pauseOnHover:false,
  };


const Main = () => {

    return(
        <>
        <Helmet>
            <title>Walkzero: Discover More! Begin Now</title>
        </Helmet>
        <div className='position-relative bg-col-container-bg d-flex flex-column p-t-50 p-b-100 demo-height'>
            <div className='demo-logo-cont d-flex flex-row justify-content-start align-self-center m-b-20'>
                <Link className='' to="/"><Logo width={"73%"} className="logo-height" /></Link>
            </div>
            <div className='d-flex flex-row justify-content-between align-self-center demo-cont'>
                <div className='d-flex flex-column book-cont'>
                    <p className='col-quaternary font-weight-5 text-12 text-uppercase'>India’s Unified platform: All-in-One Human Resource Management System</p>
                    <h1 className="col-container-font font-weight-6 h1-size">Book a Demo with <span className='col-secondary'>Walkzero</span></h1>
                    <p className='font font-weight-3 text-12'>Experience Walkzero's HR & Payroll solutions firsthand! Book a demo today to see how we revolutionize your processes, streamline operations, and empower your team.</p>
                    <div className='number-cont mb-4 mt-3'>
                        <div className=''>
                            <h1 className='h1-size'><AnimatedNumber key={"demo"} num={35} />+</h1>
                            <p className='p-size'>Demo Conducted</p>
                        </div>
                        <div>
                            <h1 className='h1-size'><AnimatedNumber key={"client"} num={15} />+</h1>
                            <p className='p-size'>Interested Clients</p>
                        </div>
                    </div>
                    <div className='bg-col-light-orange rounded-21 d-flex flex-row justify-content-center align-self-center m-b--25 m-l-18 avatar-cont'>
                        <img className='z-index-1 m-t--20' src={Avatar} alt="avatar" />
                    </div>
                    <div className='p-3 rounded-15 bg-col-white d-flex flex-column w-95 align-self-center'>
                        <p className='text-12 m-b-0 text-center'>Rajasekaran Arul</p>
                        <p className='font font-weight-3 text-12 m-t-0 text-center'>GM / Page Industries</p>
                        <h3 className='font font-weight-6 text-12 text-center'>Amazing product with cutting-edge interface and optimized user flow!</h3>
                        <Quotes className="m-l-20" />
                        <p className='font font-weight-3 text-12 w-85 align-self-center'>Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments. Its user-friendly interface and comprehensive features have made managing our workforce a breeze, resulting in increased productivity and efficiency across the board.</p>
                        <Quotes style={{rotate:"180deg"}} className="align-self-end m-t--20 m-r-20"/>
                    </div>
                    <h1 className='text-22 col-container-font font-weight-6 mt-5'>Our Industry <span className='col-secondary'>Support</span></h1>
                    <AutoPlay2 data={data} settings={settings} />
                </div>
                <Form />
            </div>
            <Shape1 className='position-absolute bottom-0 left-percentage-30 z-index-0 low-opacity' />
            <Shape2 className='position-absolute top--50 left--20' />
        </div>
        </>
    )
}

export default Main