export const container = {
    hidden:{
        opacity:1
    },
    visible: (i = 1) => ({
        opacity:1,
        transition:{
            staggerChildren: 0.2,
            delayChildren: 0.04 * i,
        }
    }),
}

export const container2 = {
    hidden:{
        opacity:1
    },
    visible: (i = 1) => ({
        opacity:1,
        transition:{
            staggerChildren: 0.08,
            delayChildren: 0.04 * i,
        }
    }),
}

export const child = {
    visible:{
        opacity:1,
        transition:{
            duration:1, 
            ease:"easeIn",
        }
    },
    hidden:{
        opacity:0
    }
}


export const child1 = {
    visible: {
        opacity:1,
        x:0,
        transition:{
            duration:0.5,
            type:"tween"
        }
    },
    hidden: {
        opacity:0,
        x:-50
    }
}

export const child2 = {
    visible: {
        opacity:1,
        x:0,
        transition:{
            duration:0.5,
            type:"tween"
        }
    },
    hidden: {
        opacity:0,
        x:50
    }
}


export const child3 = {
    visible: {
        opacity:1,
        y:0,
        transition:{
            duration:0.5,
            type:"tween"
        }
    },
    hidden: {
        opacity:0,
        y:50
    }
}


export const child4 = {
    visible: {
        opacity:1,
        x:0,
        transition:{
            duration:0.5,
            type:"tween",
            staggerChildren:0.2,
            delayChildren:0.1
        }
    },
    hidden:{
        opacity:0,
        x:-50
    }
}

export const child5 = {
    visible: {
        scale:1,
        transition:{
            duration:0.7,
            staggerChildren:0.2,
            delayChildren:0.1,
            type:"spring"
        }
    },
    hidden: {
        scale:0.5
    }
}