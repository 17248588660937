import { useState, useContext} from 'react'
import { Link, useNavigate } from 'react-router-dom'
import {TailSpin} from 'react-loader-spinner'

import './index.scss'
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import Celebration from "../../../assets/gifs/celebration.gif"
import TextField from '@mui/material/TextField';
// import PhoneInput from "react-phone-input-2"
// import 'react-phone-input-2/lib/style.css';
import { BaseBtn } from '../../../styled-components'
import { MenuContext } from '../../../app'

const msg = 'This field is required';

const Form = () => {

    const [email, setEmail] = useState('');
    const [number, setNumber] = useState('');
    const [noOfEmployees, setNoOfEmployees] = useState('');
    const [title, setTitle] = useState('');
    const [emailRequired, setEmailRequired] = useState(false);
    const [numberRequired, setNumberRequired] = useState(false);
    const [employeeRequired, setEmployeeRequired] = useState(false);
    const [titleRequired, setTitleRequired] = useState(false);
    const [loading, setLoading] = useState(false)
    const [celebrate, setCelebrate] = useState(false)
    const [emailErMsg, setEmailErMsg] = useState(msg);
    const [numberErMsg, setNumberErMsg] = useState(msg);
    const [employeeErMsg, setEmployeeErMsg] = useState(msg);
    const [titleErMsg, setTitleErMsg] = useState(msg);

    const {isOn} = useContext(MenuContext)

    const navigate = useNavigate();

    const pattern = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    const changeEmail = (event:React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
    }

    const changeNumber = (event:React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if ((parseInt(inputValue) > 0 && /^\d+$/.test(inputValue)) || (inputValue === '')) {
            setNumber(inputValue);
        }
    }

    const changeNoOfEmploy = (event:React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if ((parseInt(inputValue) > 0 && /^\d+$/.test(inputValue)) || (inputValue === '')) {
            setNoOfEmployees(inputValue);
        }
    }

    const changeTitle = (event:React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        
        if (/^[A-Za-z&-]+$/.test(inputValue) || inputValue === '') {
            setTitle(inputValue);
        }
    }

    const sendMail = async() => {
        const url = `${process.env.REACT_APP_BASE_URL}/book-a-demo`;
        const data = {"email":email, "phone":number, "no_of_employees":noOfEmployees, "title":title}
        const options = {
            method: 'POST', 
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify(data)
        }

        try{
            const response = await fetch(url, options)
    
            if(response.ok){
                setLoading(false)
                setCelebrate(true)
                setTimeout(() => {
                    navigate('/')
                }, 5000)
            }else{
                throw new Error("failed to book a demo")
            }
        }catch(error:any){
            setLoading(false)
            console.error('Error in sending email:', error.message)
        }

    }

    const submitForm:React.FormEventHandler<HTMLFormElement> = (event) => {
        event.preventDefault();
        if(email !== '' && number !== '' && noOfEmployees !== '' && title !== ''){
            if(pattern.test(email)){
                setLoading(true)
                setEmailRequired(false)
                setEmployeeRequired(false)
                setNumberRequired(false)
                setTitleRequired(false)
                sendMail();
            }
            else{
                setEmailRequired(true)
                setEmailErMsg('Please enter a valid email')
            }
           
        }
        else{
            if(email === ''){
                setEmailRequired(true)
            }else{
                setEmailRequired(false)
            }

            if(number === ''){
                setNumberRequired(true)
            }else{
                if(number.length !== 10){
                    setNumberRequired(true)
                    setNumberErMsg('Please enter a valid phone number')
                }
                else{
                    setNumberRequired(false)
                }
            }

            if(noOfEmployees === ''){
                setEmployeeRequired(true)
            }else{
                setEmployeeRequired(false)
            }

            if(title === ''){
                setTitleRequired(true)
            }else{
                setTitleRequired(false)
            }
        }
    }

    const checkEmail = (event:React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;

        if(!pattern.test(inputValue)){
            setEmailRequired(true)
            setEmailErMsg('Please enter a valid email')
        }
        if(inputValue === ''){
            setEmailRequired(true)
            setEmailErMsg(msg)
        }
        if(pattern.test(inputValue)){
            setEmailRequired(false)
            setEmailErMsg('')
        }
    }

    const checkNumber = (event:React.FocusEvent<HTMLInputElement>) => {
        const inputValue = event.target.value;
        // let flagEl = document.querySelector('.react-tel-input .flag-dropdown');

        // let spanEl = document.getElementById('label');
        // spanEl.classList.remove('place-up')
        // spanEl.classList.add('place-down')

        if(inputValue === ''){
            setNumberRequired(true)
            setNumberErMsg(msg)
            // spanEl.classList.remove('hide');
            // spanEl.style.color = "#D32F2F";
            // flagEl.classList.remove('d-block');
            // flagEl.classList.add('d-none');

        }
        else{
            // spanEl.classList.add('hide')
            // flagEl.classList.remove('d-none');
            // flagEl.classList.add('d-block');
            if(number.length === 10){
                setNumberRequired(false)
                setNumberErMsg('')
            }
            else{
                setNumberRequired(true)
                setNumberErMsg('Please enter a valid phone number')
            }
            
        }
    }

    const checkEmployee = (event:React.FocusEvent<HTMLInputElement>) => {
        if(event.target.value === ''){
            setEmployeeRequired(true)
            setEmployeeErMsg(msg)
        }
        else{
            setEmployeeRequired(false)
            setEmployeeErMsg('')
        }
    }

    const checkTitle = (event:React.FocusEvent<HTMLInputElement>) => {
        if(event.target.value === ''){
            setTitleRequired(true)
            setTitleErMsg(msg)
        }
        else{
            setTitleRequired(false)
            setTitleErMsg('')
        }
    }

    // const typeNumber = (value) => {
    //     setNumber(value)
    // }
    

    // const showArrow = () => {
    //     let flagEl = document.querySelector('.react-tel-input .flag-dropdown');
    //     flagEl?.classList.remove('d-none');
    //     flagEl?.classList.add('d-block');

    //     let spanEl = document.getElementById('label');
    //     spanEl?.classList.remove('place-down');
    //     spanEl?.classList.add('place-up');
    // }



    return(
        <div style={{height:celebrate?"450px":"auto"}} className='position-relative d-flex flex-column align-items-center rounded-21 p-t-30 p-b-30 p-l-30 p-r-30 form-bg'>
           {!celebrate && <><h1 className="col-container-font font-weight-6 text-32 z-index-1">Discover More! <span className='col-secondary'>Begin Now</span></h1>
            <form onSubmit={submitForm} className='d-flex flex-column justify-content-start align-items-center w-100' noValidate>
                <TextField required className='inputs' error={emailRequired?true:false} margin="normal" id="outlined-basic" label="Business Email" variant="outlined" size="small" type="email" value={email} onBlur={checkEmail} onChange={changeEmail} />
                <span style={{opacity:emailRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{emailErMsg}</span>
                {/* <div className='w-100 position-relative d-flex flex-column justify-content-start align-items-center'> */}
                <TextField required className='inputs' error={numberRequired?true:false} margin="normal" id="outlined" variant="outlined" size="small" label='Phone Number' value={number} onBlur={checkNumber} onChange={changeNumber} />
                <span style={{opacity:numberRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{numberErMsg}</span>
                {/* {<PhoneInput international placeholder="" inputStyle={{border:'1px solid #000000'}} defaultCountry='IN' value={number} onChange={(value) => typeNumber(value)} onBlur={checkNumber} onFocus={showArrow} />}
                <span id="label" style={{color:'rgba(0, 0, 0, 0.57)'}} className='font-weight-4 font-1 align-self-start m-l-25 position-absolute place-down'>Phone Number *</span>
                </div> */}
                {/* {numberRequired && <p className='text-12 font-1 font-weight-5 text-danger m-t--3 align-self-start m-b-5 m-l-25'>{numberErMsg}</p>} */}
                <TextField required className='inputs' error={employeeRequired?true:false} margin="normal" id="outlined-basic" variant="outlined" size="small" type="text" label='Number of employees' value={noOfEmployees} onBlur={checkEmployee} onChange={changeNoOfEmploy}/>
                <span style={{opacity:employeeRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{employeeErMsg}</span>
                <TextField required className='inputs' error={titleRequired?true:false} margin="normal" id="outlined-basic" variant="outlined" size="small" type="text" label='Your Title' value={title} onBlur={checkTitle} onChange={changeTitle} />
                <span style={{opacity:titleRequired?1:0}} className="text-12 font-1 align-self-start m-l-25 col-error msg-height">{titleErMsg}</span>
                <BaseBtn isOn={isOn} type="submit" className='btn-4 rounded-9 text-16 font-weight-6 demo-btn align-self-center m-t-15 m-b-10'>Request a DEMO</BaseBtn>
                {loading && <div className="align-self-center"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
            </form>
            <p className='text-12 font-1 font-weight-4 new-gray-900 text-center'>By Providing your information, you hereby consent to the Walkzero <Link to="" className='decoration'>Cookie Policy</Link> and <Link to="" className='decoration'>Privacy Policy</Link>.</p></>}
            {celebrate &&<div className='d-flex flex-column justify-content-center align-items-center mt-4'>
                <div className='d-flex justify-content-between w-100'>
                    <img src={Celebration} alt="gif" />
                    <img src={Celebration} alt="gif" />
                </div>
                <h1 className='col-container-font font-weight-6 h1-size m-t-20 m-b-16'>Thank <span className='col-secondary'>you!</span></h1>
                <p className='text-12 font-1 font-weight-4 new-gray-900 text-center'>Your request has been received, and our sales team will reach out to you shortly to provide further assistance and get you started with your account.</p>
            </div>}
            <Star className="position-absolute top-20 right-30" />
            <Star style={{width:20, height:20}} className="position-absolute top-50 right-23" />
        </div>
    )
}

export default Form