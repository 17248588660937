import styled from "styled-components";

interface ContrastProps {
    isOn: boolean;
}

export const BaseBtn = styled.button<ContrastProps>`
    background-color: ${(props) => (props.isOn ? "#1A2D48 !important" : "#F45600 !important")};
`;

export const DemoBtn = styled.button<ContrastProps>`
    border: ${(props) => (props.isOn ? "1px solid #1A2D48 !important" : "1px solid #F45600 !important")};
    color: ${(props) => (props.isOn ? "#1A2D48" : "#F45600")};
    &:after {
        background: ${(props) => (props.isOn ? "rgba(26, 45, 72, 0.7) !important" : "rgb(244, 86, 0, 0.7) !important")};
    }
`;

export const Round = styled.div<ContrastProps>`
    border: ${(props) => (props.isOn ? "1px solid #1A2D48 !important" : "1px solid #F45600 !important")};
`;


export const BaseSvg = styled.span<ContrastProps>`
    svg {
        path{
            stroke: ${(props) => (props.isOn ? "#1A2D48" : "#F45600")};
        }
    }
`;

export const BaseSvg2 = styled.span<ContrastProps>`
    svg {
        path{
            fill: ${(props) => (props.isOn ? "#1A2D48" : "#F45600")};
        }
    }
`;

