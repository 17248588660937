import './index.scss'
import AttendanceCard from '../attendance'
import SlideItem from '../slide-items'
import {ReactComponent as Circle} from "../../../assets/logos/circle.svg"
import Holiday from "../../../assets/images/holiday.png"
import LeaveType from "../../../assets/images/leave-type.png"
import LeavePolicy from "../../../assets/images/leave-policy.png"
import Bio from "../../../assets/images/bio.png"
import Geo from "../../../assets/images/geo.png"
import Ip from "../../../assets/images/ip-address.png"
import PayrollRun from "../../../assets/images/payroll-run.png"
import PayGroup from "../../../assets/images/pay-group.png"
import PaySchedule from "../../../assets/images/pay-schedule.png"
import Compliance from "../../../assets/images/compliance.png"
import Leave from "../../../assets/images/leave.png"
import AttendanceList from "../../../assets/images/attendance-list.png"
import {useAnimation, motion} from "framer-motion"
import { useState, useEffect, useContext } from "react";
import { useInView } from "react-intersection-observer";
import { MenuContext } from '../../../app'



export const slider1 = [
    {id:"slide0", name:"Performance"},
    {id:"slide1", name:"Mobile HRMS"},
    {id:"slide2", name:"Employees Self Service (ESS)"},
    {id:"slide3", name:"Self-Onboarding"},
    {id:"slide4", name:"Employee Management"},
]

export const slider2 = [
    {id:"slide5", name:"Compliance"},
    {id:"slide6", name:"HR Edge"},
    {id:"slide7", name:"Data Analytics"},
    {id:"slide8", name:"HCM"},
    {id:"slide9", name:"Workflows & Documents"},
]

export const settings1 = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    autoplay: true,
    speed: 6000,
    autoplaySpeed:0,
    cssEase: "linear",
    centerMode:true,
    pauseOnHover:false,
  };

export const settings2 = {
    dots: false,
    infinite: true,
    slidesToShow: 2,
    autoplay: true,
    speed: 6000,
    autoplaySpeed:0,
    cssEase: "linear",
    centerMode:true,
    rtl:true,
    pauseOnHover:false,
  };

const tabs = [
    {id:"data1", name:"Leave", span:"Management", active:true, width:"25%", items:[
        {id:"card1", title:"Leave Types", content:"Categorize various types of leave such as sick leave, vacation, and maternity leave to accurately track and manage employee time off.", img:LeaveType},
        {id:"card2", title:"Holidays", content:"Maintain a comprehensive calendar of holidays to facilitate scheduling and ensure compliance with company and statutory requirements", img:Holiday},
        {id:"card3", title:"Leave Policy", content:"Establish clear guidelines and regulations governing employee leave entitlements, ensuring fairness and consistency across the organization.", img:LeavePolicy},
    ]},
    {id:"data2", name:"Attendance", span:"Tracking", active:false, width:"50%", items:[
        {id:"card4", title:"Biometric", content:"Utilize biometric technology for secure and reliable employee attendance tracking, minimizing errors and ensuring data integrity.", img:Bio},
        {id:"card5", title:"Web Clock-in", content:"Enable employees to clock in and out conveniently through a web-based interface, facilitating remote work and flexible scheduling.", img:Ip},
        {id:"card6", title:"Geofencing", content:"Utilize geolocation technology to verify employee attendance at specific locations, enhancing accountability and preventing time theft.", img:Geo},
    ]},
    {id:"data3", name:"Payroll", span:"Processing", active:false, width:"75%", items:[
        {id:"card7", title:"Statutory Compliance", content:"Adhere to legal and regulatory requirements related to payroll, taxes, and employee benefits to mitigate risks and maintain compliance.", img:Compliance},
        {id:"card8", title:"Payroll Group", content:"Organize employees into different payroll groups based on criteria such as department or location, simplifying payroll management and reporting.", img:PayGroup},
        {id:"card9", title:"Payroll Scheduling", content:"Schedule payroll runs efficiently to meet deadlines and ensure employees are paid on time without delays or errors.", img:PaySchedule},
    ]},
    {id:"data4", name:"HR Analytics", span:"", active:false, width:"100%", items:[
        {id:"card10", title:"Leave Metrics", content:"Track and analyze key metrics related to employee leave, such as usage patterns, absenteeism rates, and leave balances.", img:Leave},
        {id:"card11", title:"Attendance Metric", content:"Measure and evaluate attendance metrics to assess employee punctuality, absenteeism trends, and overall workforce attendance patterns.", img:AttendanceList},
        {id:"card12", title:"Payroll Metrics", content:"Analyze payroll data to assess labor costs, overtime trends, payroll accuracy, and other key metrics to optimize payroll processes and expenses.", img:PayrollRun},
    ]},
]

const LeaveManagement = () => {

    const ctrls = useAnimation();

    const {isOn} = useContext(MenuContext)

    const { ref, inView } = useInView({
        threshold: 0.1,
        triggerOnce: true,
      });

      
    const [tabsList, setTabsList] = useState(tabs);
    const [activeIndex, setActiveIndex] = useState(1);
    
    const clickTab = (id:string) => {
        setTabsList(prevTabsList => prevTabsList.map(tab => {
            if(tab.id === id){
                if(tab.active === true){
                    return tab
                }
                return {...tab, active: !tab.active};
            }
            
            return {...tab, active: false};
        }));
    }
        
    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }

        const intervalId = setInterval(() => {
            setActiveIndex((prevActiveIndex) => (prevActiveIndex + 1) % tabsList.length);
            setTabsList(prevTabsList => prevTabsList.map((tab, index) => {
                return index === activeIndex ? { ...tab, active: !tab.active } : {...tab, active: false }
        }))
        }, 7800);

        return () => clearInterval(intervalId);

    }, [ctrls, inView, activeIndex, tabsList])


    const container = {
        hidden:{
            opacity:1
        },
        visible: (i = 1) => ({
            opacity:1,
            transition:{
                staggerChildren: 0.08,
                delayChildren: 0.04 * i,
            }
        }),
    }

    const child = {
        visible:{
            y:0,
            opacity:1,
            transition:{
                duration:1, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:50,
            opacity:0
        }
    }


    return(
        <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls}  className='d-flex flex-column justify-content-start align-items-center p-t-80 p-b-100 leave-cont align-self-center position-relative'>
            <motion.div variants={child} className="col-quaternary font-weight-6 text-12 text-center z-index-1 text-uppercase">India’s Unified platform: Your all-in-one HRMS software for seamless management</motion.div>
            <motion.h1 variants={child} className='col-container-font h1-size m-b-5 z-index-1'>Together, we collaborate to</motion.h1>
            <motion.h1 variants={child} className='col-container-font h1-size m-t--5 m-b-0'><span className="col-secondary">enhance</span> your processes</motion.h1>
            <motion.p variants={child} className='font m-t-16 mb-2 text-14 font-weight-3 text-center'>Transform your HR operations with Walkzero, ensuring every step of your employees' lifecycle is optimized for success</motion.p>
            <motion.div variants={child} className='d-flex flex-row justify-content-between font text-16 font-weight-5 options z-index-1'>
                {tabsList.map(each => {
                    return <div key={each.id} className='d-flex flex-column justify-content-center w-100'>
                                <motion.p style={{cursor:"pointer", color:each.active?isOn?"#1A2D48":"#F45600":"#1A2D48"}} className={each.active?'active-tab p-big-size':'op1 p-big-size'} onClick={() => clickTab(each.id)}>{each.name} <span className='hide-in-mob'>{each.span}</span></motion.p>
                                <motion.div variants={child} className='prog1'>
                                    <motion.div style={{backgroundColor:isOn?"#1A2D48":"#F45600"}} initial={{ scaleX: 0, originX: 0 }} animate={{ scaleX:each.active?1:0, opacity:each.active?1:0 }} transition={{ duration: each.active?8:0, repeat: Infinity, ease:"linear" }} className='prog'>
                                    </motion.div>
                                </motion.div>
                            </div>
                })}
            </motion.div>
            <motion.div variants={child} className='d-flex flex-row justify-content-between align-items-center w-100 card-cont'>
                {tabsList.map(each => {
                    if(each.active === true){
                        const {items, active} = each
                        return items.map(value => (
                            <AttendanceCard key={value.id} active={active} item={value} />
                        ))
                    }
                    return null;
                })}
            </motion.div>
            <SlideItem data={slider1} settings={settings1} width={{width:"70%"}}/>
            <SlideItem data={slider2} settings={settings2} width={{width:"50%"}}/>
            <Circle className="position-absolute right--40 top--50" />
        </motion.div>
    )
}

export default LeaveManagement