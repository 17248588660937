import { useEffect, useState } from "react";
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container2, child} from "../../../../variants"

import {ReactComponent as SlideIcon} from '../../../../assets/logos/geo-slide-icon.svg'
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'

const hrData = [
    {id:"data1", icon:<SlideIcon />, title:"Geo-Fence", content:"Create virtual boundaries for employee presence during work hours. Automatic clock-ins and clock-outs are triggered based on movement within or outside the designated area."},
    {id:"data2", icon:<SlideIcon />, title:"Interval Tracking", content:"Track employee attendance at customizable intervals, tailored to your preferences. Data synchronization occurs upon reconnection, ensuring consistent and accurate location tracking."},
    {id:"data3", icon:<SlideIcon />, title:"Continuous Tracking", content:"Enable real-time monitoring of employee locations and activities. Enhance productivity by optimizing workflow and resource allocation based on live updates and insights."},
]

const GeoFenceSlide = () => {

    const [scrollRight, setScrollRight] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const [data] = useState(hrData);

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
    });

    useEffect(() => {
        const updateDimensions = () => {
            const scrollingContainer:any = document.getElementById("hrSlideContainer");
            const child:any = document.getElementById("hrChild");
            const scrollAmount = Math.floor(scrollingContainer.offsetWidth / (child.offsetWidth))
            const right = hrData.length - scrollAmount
            setScrollRight(right)
        }

        updateDimensions();

        window.addEventListener("resize", updateDimensions);

        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }

        return () => {
            window.removeEventListener("resize", updateDimensions);
        };
        
    }, [data, ctrls, inView])

    const slideRight = () => {

        const scrollingContainer:any = document.getElementById("hrSlideContainer");
        const child:any = document.getElementById("hrChild");
       
        setScrollRight(prevScrollRight => prevScrollRight - 1);
        setScrollLeft(prevScrollLeft => prevScrollLeft + 1);
    
        
        scrollingContainer.scrollLeft += (child.offsetWidth + 40)

    }

    const slideLeft = () => {

        const scrollingContainer:any = document.getElementById("hrSlideContainer");
        const child:any = document.getElementById("hrChild");

        setScrollLeft(prevScrollLeft => prevScrollLeft - 1);
        setScrollRight(prevScrollRight => prevScrollRight + 1);

       
        scrollingContainer.scrollLeft -= (child.offsetWidth + 40)
       
    }

    const shouldShowLeftArrow = scrollLeft > 0;
    const shouldShowRightArrow = scrollRight > 0;

    
  return (
    <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} id="hrSlide" className='hr-slide-par p-t-50 mb-40 d-flex flex-column'>
        <motion.div variants={child} className='hr-slide-child  d-flex flex-column  align-self-center'>
            <h3 className='h1-size font-weight-600 text-center'>Stay on Track with Smart <span className="col-secondary"> Geo-Fencing! </span></h3>
            <div className='hr-slide-one d-flex flex-column align-self-center'>
                <p className='font-1 p-size font-weight-400 text-center'> Optimize attendance with geo-fencing and geo-interval punch, ensuring real-time location tracking and compliance for mobile and remote teams.</p>
            </div>
            <div className='hr-slide-two mt-20 d-flex flex-column align-items-center '>
                <ul id="hrSlideContainer" className='hr-slide-ul d-flex flex-row justify-content-between align-self-center'>
                        {hrData.map((each)=>
                            <li key={each.id} id="hrChild" className='hr-slide-items m-r-40'>
                                {each.icon}
                                <h3 className='hr-slide-head mt-10 text-18 font-weight-600'>{each.title}</h3>
                                <p className='hr-slide-para p-size font-weight-400'>{each.content}</p>
                            </li>
                        )}
                </ul>
            </div>
            <div className="hr-slide-icons d-flex flex-row justify-content-between align-self-center">
                <div className="slide-icon-flat d-flex flex-row align-items-center p-l-15">
                    <div style={{backgroundColor:scrollLeft === 0?"#FF5722":"#D9D9D9"}} className="w-px-30 h-px-3 rounded-5 m-r-10"></div>
                    <div style={{backgroundColor:scrollRight === 0?"#FF5722":"#D9D9D9"}} className="w-px-30 h-px-3 rounded-5 slide-line"></div>
                </div>
                <div className="d-flex flex-row icon-con">
                   {shouldShowLeftArrow && <LeftArrow onClick={slideLeft} className='icon-left-arrows'/>}
                    {shouldShowRightArrow && <LeftArrow onClick={slideRight} className='icon-right-arrows' />}
                </div>
            </div>
        </motion.div>
    </motion.div>
  )
}

export default GeoFenceSlide