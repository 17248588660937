import Slider from "react-slick";
import './index.scss'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Round, BaseSvg } from "../../../styled-components";
import { MenuContext } from "../../../app"
import { useContext } from "react"

type FocusOnSelectProps = {
    data:{
        id:string
        icon: JSX.Element
        name: string 
        content: string 
        isClicked: boolean
    }[]
    clickFeature: (id:string) => void
}


function FocusOnSelect(props:FocusOnSelectProps) {

    const {data, clickFeature} = props 

    const {isOn} = useContext(MenuContext);

    const settings = {
      focusOnSelect: true,
      infinite: true,
      slidesToShow: 2,
      slidesToScroll: 1,
      speed: 1000,
      centerMode:true,
    };

    const handleClick = (id:string) => {
        clickFeature(id)
    }

    return (
      <div className="show-mob-feature slider-container slide-size2">
        <Slider {...settings}>
          {data.map(each => (
             <div key={each.id} className="platform-list d-flex flex-column justify-content-center align-items-center" onClick={() => handleClick(each.id)}>
                <Round isOn={isOn} className="round">
                  <BaseSvg isOn={isOn} >{each.icon}</BaseSvg>
                </Round>
                <div className="titles">
                    <p className={each.isClicked?"platform-title text-9":"platform-title-normal text-9"}>{each.name}</p>
                    {/* {isClicked && <p className="title-content">{content}</p>} */}
                </div>
            </div>
          ))}
        </Slider>
      </div>
    );
  }
  
  export default FocusOnSelect;