import "./index.scss"
import {ReactComponent as Quotes} from "../../../../assets/logos/quotes.svg"
import Avatar from "../../../../assets/images/avatar.png"
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { container, child} from "../../../../variants"
import { useEffect } from "react";

const data = [
    {id:"task1", name:"Rajasekaran Arul", img:Avatar, company:"GM / Page Industries", title:"Amazing product with cutting-edge interface and optimized user flow!", content:"Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments. Its user-friendly interface and comprehensive features have made managing our workforce a breeze, resulting in increased productivity and efficiency across the board."},
    // {id:"task2", name:"Rajasekaran Arul", img:Avatar, company:"GM / Page Industries", title:"Amazing product with cutting-edge interface and optimized user flow!", content:"Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments. Its user-friendly interface and comprehensive features have made managing our workforce a breeze, resulting in increased productivity and efficiency across the board."},
]


const HrTasks = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: false,
    });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    return (
        <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls}  className="d-flex flex-column hr-task-cont align-self-center p-t-60 p-b-60">
            <motion.h1 variants={child} className="col-container-font h2-size text-center hide-mb">Unlock peak efficiency with the premier HCM suite, <br /><span className="col-secondary">automating</span> and <span className="col-secondary">simplifying</span> HR tasks </motion.h1>
            <motion.h1 className="col-container-font h2-size text-center show-mb">Unlock peak efficiency with the premier HCM suite,<span className="col-secondary">automating</span> and <span className="col-secondary">simplifying</span> HR tasks </motion.h1>
            <motion.div className="d-center flex-row flex-wrap hr-avatar-cont m-t-40">
                {data.map(each => {
                    return <motion.div variants={child} key={each.id} className="inner-hr-task-cont d-center flex-column">
                        <div className='bg-col-light-orange rounded-21 d-flex flex-row justify-content-center align-self-center m-b--25 avatar-cont'>
                            <img className='z-index-1 m-t--20' src={each.img} alt="avatar" />
                        </div>
                        <div className='p-3 rounded-15 bg-col-white d-flex flex-column w-95 align-self-center task-border'>
                            <p className='text-12 m-b-0 text-center'>{each.name}</p>
                            <p className='font font-weight-3 text-12 m-t-0 text-center'>{each.company}</p>
                            <h3 className='font font-weight-6 text-12 text-center'>{each.title}</h3>
                            <Quotes className="m-l-20" />
                            <p className='font font-weight-3 text-12 w-85 align-self-center'>{each.content}</p>
                            <Quotes style={{rotate:"180deg"}} className="align-self-end m-t--20 m-r-20"/>
                        </div>
                    </motion.div>
                })}
            </motion.div>
        </motion.div>
    )
}

export default HrTasks