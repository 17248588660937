import "./index.scss"
import {ReactComponent as Not} from "../../assets/logos/not-found.svg"

const NotFound = () => {

    return(
        <div className="d-flex flex-column justify-content-center bg-col-light-blue-gray align-items-center p-t-60 p-b-60">
            <Not />
        </div>
    )
}

export default NotFound