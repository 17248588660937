import './index.scss'
import {motion} from "framer-motion"
import { FaMinus } from "react-icons/fa6";
import { FaPlus } from "react-icons/fa6";
import { MenuContext } from '../../../app';
import { useContext, useEffect, useState } from 'react';

type FaqItemsProps = {
    item: {
        id: string 
        question: string 
        answer: string 
        is_open: boolean
    }
    checkIsOpen: (id:string) => void
}


const FaqItems = (props:FaqItemsProps) => {    

    const {item, checkIsOpen} = props 
    const {id, question, answer, is_open} = item

    const {isOn} = useContext(MenuContext)

    const [isMobile, setIsMobile] = useState(false)
    const [screenWidth, setScreenWidth] = useState<number>(window.innerWidth);


    useEffect(() => {
        const handleResize = () => {
            setScreenWidth(window.innerWidth);
        };

        setScreenWidth(window.innerWidth);

        window.addEventListener('resize', handleResize);

        if(window.innerWidth <= 860){
            setIsMobile(true)
        }
        else{
            setIsMobile(false)
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [screenWidth])

    const toggleFaq = () => {
        checkIsOpen(id)
    }

    const child = {
        visible:{
            y:0,
            opacity:1,
            transition:{
                duration:1, 
                type:"tween",
                ease:"easeIn"
            }
        },
        hidden:{
            y:50,
            opacity:0
        }
    }

    return(
        <>
        <motion.div style={{cursor:"pointer"}} variants={child} className='d-flex flex-row justify-content-between align-items-center w-100 mt-3 m-b--10' onClick={toggleFaq}>
            <p className={is_open?'p2-size font-weight-4 font-1 col-secondary':'p2-size font-weight-4 font-1 col-black'}>{question}</p>
            {is_open?<FaMinus fill={isOn?"#1A2D48":"#F45600"} />:<FaPlus fill={isOn?"#1A2D48":"#F45600"} width={14} height={14}/>}
        </motion.div>
        <motion.div initial={{opacity:0, height:0}} animate={{ opacity:is_open?1:0, height:is_open?isMobile?"140px":"80px":0}} transition={{opacity:{ duration:is_open?1:0.1, type:"tween" }, height:{ duration:0.5, type:"tween" }}} className='text-12 font light-blue p-3'>{answer}</motion.div>
        <motion.div variants={child} className='border-btm w-100'></motion.div>
        </>
    )
}

export default FaqItems