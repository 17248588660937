import "./index.scss"
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'
import {ReactComponent as SlideIcon} from '../../../../assets/logos/attend-slide-icon.svg'
import {ReactComponent as Point} from '../../../../assets/logos/point.svg'
import {ReactComponent as Hr} from '../../../../assets/logos/pink-hr.svg'
import {ReactComponent as Manager} from '../../../../assets/logos/pink-manager.svg'
import {ReactComponent as Emp} from '../../../../assets/logos/pink-employee.svg'
import { BaseSvg2 } from "../../../../styled-components"
import { useContext } from "react"
import { MenuContext } from "../../../../app"

import ShiftManagement from '../../../../assets/logos/attend-feat-1.svg'
import AttendanceCapture from '../../../../assets/logos/attend-feat-2.svg'
import Overtime from '../../../../assets/logos/attend-feat-3.svg'
import Remotework from '../../../../assets/logos/attend-feat-4.svg'
import TrackingPolicy from '../../../../assets/logos/attend-feat-5.svg'

const hr_a = <Hr id="hr_a"/>
const manager_a = <Manager id="hr_a"/>
const emp_a = <Emp id="hr_a"/>

const data = [
    {id:"d1", num:"01", tag:"#Shift Management", head:<h1 className="col-container-font h3-size m-b-10">Shift Simplified: Mastering Time,<span className="col-secondary"> Every Hour.</span></h1>, title:"Shift Management", content:"Master your team's schedule with ease.", img:ShiftManagement, points:[{id:"p1", point:"Configure fixed and flexible shift times for all employees."}, {id:"p2", point:"Add shift allowances based on specific shift requirements."}, {id:"p3", point:"Set up configurable break durations within shifts."}, {id:"p4", point:"Customize week off days according to employee preferences."}, {id:"p5", point:"Choose versatile shift configurations for enhanced efficiency."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Customized policies foster inclusivity and streamline compliance efforts."}, {id:"r2", icon:manager_a, name:"Managers", content:" Flexible shifts optimize resource allocation and accommodate varying schedules efficiently."}, {id:"r3", icon:emp_a, name:"Employees", content:"Configurable off-day choices promote better work-life balance and enhance job satisfaction."}]},
    {id:"d2", num:"02", tag:"#Attendance Capture and Integration", head:<h1 className="col-container-font h3-size m-b-10"> Elevate Efficiency with<span className="col-secondary"> Integrated </span> Attendance Solutions!</h1>, title:"Attendance Capture and Integration", content:"Enhance productivity with integrated attendance tracking solutions.", img:AttendanceCapture , points:[{id:"p1", point:"Biometric, Web, and Geo clock-ins for precision."}, {id:"p2", point:"Integrate biometric devices for real-time data."}, {id:"p3", point:"Clock in directly from a web application, seamlessly."}, {id:"p4", point:"Monitor locations accurately with geo clock-ins."}, {id:"p5", point:"Simplify tracking with mobile apps effortlessly."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Streamline attendance management with integrated biometric, web, and geo clock-ins for efficient payroll processing and compliance adherence."}, {id:"r2", icon:manager_a, name:"Managers", content:"Access real-time attendance insights for proactive decision-making and productivity enhancement."}, {id:"r3", icon:emp_a, name:"Employees", content:"Seamlessly clock in from any location for accurate timekeeping and performance evaluation, supported by advanced tracking features."}]},
    {id:"d3", num:"03", tag:"#Overtime & Compensation", head:<h1 className="col-container-font h3-size m-b-10">Streamline Attendance, Optimize Compensation <span className="col-secondary"> - Instantly!</span></h1>, title:"Overtime & Compensation", content:"Ensure accurate attendance tracking and fair compensation with seamless regularization.", img:Overtime, points:[{id:"p1", point:"Automated overtime tracking for seamless management."}, {id:"p2", point:"Set overtime calculations for specific days"}, {id:"p3", point:"Choose between overtime pay or compensatory off."}, {id:"p4", point:"Manual overtime request options are available."}, {id:"p5", point:"Flexible, customizable overtime compensation policies."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Automated tracking simplifies payroll, minimizes errors, and saves significant time."}, {id:"r2", icon:manager_a, name:"Managers", content:"Flexible overtime settings optimize schedules, ensuring fair and efficient compensation."}, {id:"r3", icon:emp_a, name:"Employees", content:" Easily choose between overtime pay or compensatory off, enhancing work-life balance."}]},
    {id:"d4", num:"04", tag:"#Remote Work and On-Duty Policies", head:<h1 className="col-container-font h3-size m-b-10"> Smart Attendance Management for <span className="col-secondary">Modern Workplaces!</span></h1>, title:"Remote Work and On-Duty Policies", content:"Customize and track WFH and on-duty attendance with flexible, accurate settings.", img:Remotework, points:[{id:"p1", point:"Customize attendance settings for WFH and on-duty tasks."}, {id:"p2", point:"Differentiate attendance policies for remote and on-site work."}, {id:"p3", point:"Enable tailored settings for specific teams and roles."}, {id:"p4", point:"Choose preferred attendance tracking modes for WFH."}, {id:"p5", point:"Ensure accurate attendance with flexible tracking options."}, {id:"p6", point:"Track attendance by hours, ensuring comprehensive coverage."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Streamlined attendance policies ensure compliance and accurate reporting."}, {id:"r2", icon:manager_a, name:"Managers", content:"Flexible settings enhance team management and productivity tracking."}, {id:"r3", icon:emp_a, name:"Employees", content:" Clear attendance rules improve work-life balance and job satisfaction."}]},
    {id:"d5", num:"05", tag:"#Attendance tracking policy and guidelines", head:<h1 className="col-container-font h3-size m-b-10"> <span className="col-secondary">Automate Attendance</span> Fixes and Payroll Processing!</h1>, title:"Attendance tracking policy and guidelines", content:"Handle attendance issues effortlessly, auto-penalize discrepancies, and integrate with payroll for smooth processing.", img:TrackingPolicy, points:[{id:"p1", point:"Raise attendance regularization requests for errors."}, {id:"p2", point:"Customize hourly off permissions to fit policy."}, {id:"p3", point:"Auto-penalize absences with leave deductions."}, {id:"p4", point:"Automate payroll with attendance integration."}, {id:"p5", point:"Ensure accurate payroll processing for all."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Streamline attendance management, enhancing efficiency and compliance with customizable leave policies and insightful analytics."}, {id:"r2", icon:manager_a, name:"Managers", content:" Optimize team scheduling and resource allocation with flexible attendance tracking options and real-time insights into attendance patterns."}, {id:"r3", icon:emp_a, name:"Employees", content:"Simplify leave requests and ensure accurate attendance records with user-friendly interfaces, fostering work-life balance and employee satisfaction."}]},
]


const SecureAttendanceManage = () => {

    const {isOn} = useContext(MenuContext)

    return(
        <div className="d-flex flex-column secure-hub-cont align-self-center p-t-30 p-b-60">
            {data.map((each) => {
                 return <div style={{top:`-80px`}} className="m-t-30 cont" key={each.id}>
                        <span className="text-11 font-weight-6 rounded-5 bg-col-new-light-blue-200 p-1">{each.num}</span>
                        <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>{each.tag}</p>
                        {each.head}
                        <LeftArrow style={{rotate:"-90deg"}} className="m-b--10 move-arrow" />
                        <div className="d-flex flex-column border-left p-l-10">
                        <div className="d-flex flex-column m-t-30">
                            <div className="d-flex flex-row justify-content-between align-items-center hr-feature-cont">
                                <div className="d-flex flex-column inner-hr-feature-cont">
                                    <div className="d-flex flex-row align-items-center bg-col-grey-green rounded-16 p-x-10 p-y-10 title-cont">
                                        <SlideIcon />
                                        <span className="m-l-10 font-weight-6">{each.title}</span>
                                    </div>
                                    <p className="font-1 p-size font-weight-4 m-l-10">{each.content}</p>
                                    <ul className="p-l-0 hr-hub-list m-l-10">
                                        {each.points.map(item => (
                                            <li className="p3-size col-points-clr font-1 font-weight-4 m-b-30 m-t-30" key={item.id}><BaseSvg2 isOn={isOn}><Point className="m-r-10" /></BaseSvg2> {item.point}</li>
                                        ))}
                                    </ul>
                                </div>
                                <img src={each.img} alt="Hr hub" className="attend-feature-img"/>
                            </div>
                            <div className="d-center flex-row m-t-30">
                                <LeftArrow width={40} height={40} style={{rotate:"180deg"}} className="m-l--30 bg-light-pink rounded-20" />
                                <div className="d-flex flex-row justify-content-between align-items-start rounded-16 role-cont m-l--10">
                                    {each.roles.map(role => (
                                        <div key={role.id} className="d-flex flex-column role-child-cont">
                                            <span className="col-head-clr text-16 font-weight-4 role-op">{role.icon} {role.name}</span>
                                            <p className="col-head-clr font-1 p-size font-weight-4 mb-0 m-t-10">{role.content}</p>
                                        </div>
                                    ))}
                                </div>  
                            </div>
                        </div>
                    </div>
            </div>})}
        </div>
    )
}

export default SecureAttendanceManage