import "./index.scss"
import { useSpring, animated } from "react-spring";

type AnimatedNumberProps = {
    num: Number
}


const AnimatedNumber = (props:AnimatedNumberProps) => {

    const {num} = props

    const { number } = useSpring({
        from: { number: 0 },
        to: { number: num },
        config: { duration: 1000 }
      });

    const formattedValue = number.to((val) => val < 100 ? Math.round(val) : Math.round(val).toLocaleString());

    return(
        <animated.span>
            {formattedValue}
        </animated.span>
    )
}

export default AnimatedNumber