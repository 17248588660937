import "./index.scss"
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'
import {ReactComponent as SlideIcon} from '../../../../assets/logos/leave-slide-icon.svg'
import {ReactComponent as Point} from '../../../../assets/logos/point.svg'
import {ReactComponent as Hr} from '../../../../assets/logos/hr-team.svg'
import {ReactComponent as Manager} from '../../../../assets/logos/manager.svg'
import {ReactComponent as Emp} from '../../../../assets/logos/employee.svg'
import { BaseSvg2 } from "../../../../styled-components"
import { useContext } from "react"
import { MenuContext } from "../../../../app"

import leaveCustomization from '../../../../assets/logos/leave-feat-1.svg'
import holidayPlanning from '../../../../assets/logos/leave-feat-2.svg'
import workflowOptimization from '../../../../assets/logos/leave-feat-3.svg'
import LeaveInsights from '../../../../assets/logos/leave-feat-4.svg'

const hr_l = <Hr id="hr_l"/>
const manager_l = <Manager id="hr_l"/>
const emp_l = <Emp id="hr_l"/>

const data = [
    {id:"d1", num:"01", tag:"#Leave Type Customization", head:<h1 className="col-container-font h3-size m-b-10">Personalized Policies:<span className="col-secondary"> Tailoring Time Off</span> for Every Team Member.</h1>, title:"Leave Type Customization", content:"Customize leave options to suit your team's diverse needs", img:leaveCustomization, points:[{id:"p1", point:"Tailor leave policies to your needs."}, {id:"p2", point:"Align with culture and regulations."}, {id:"p3", point:"Define custom leave types easily."}, {id:"p4", point:"Set unique rules and accrual rates."}, {id:"p5", point:"Configure accruals by tenure or position."}], roles:[{id:"r1", icon:hr_l, name:"HR", content:"Tailored leave policies streamline compliance and nurture a positive work culture."}, {id:"r2", icon:manager_l, name:"Managers", content:"Ability to customize leave types and rules fosters efficient team scheduling and resource management."}, {id:"r3", icon:emp_l, name:"Employees", content:" Clearly defined leave options enhance work-life balance and support personal well-being."}]},
    {id:"d2", num:"02", tag:"#Holiday Planning and Coordination", head:<h1 className="col-container-font h3-size m-b-10">Seamless Holiday Harmony: Effortlessly Manage<span className="col-secondary"> Team Time Off.</span></h1>, title:"Holiday Planning and Coordination ", content:"Holiday Planning and Coordination.", img:holidayPlanning, points:[{id:"p1", point:"Easily create and share annual holiday schedules."}, {id:"p2", point:"Seamlessly communicate holiday schedules via email."}, {id:"p3", point:"View company-wide holidays across all teams and locations."}, {id:"p4", point:"Offer flexibility with floating holiday allocations."}, {id:"p5", point:"Streamline approval processes for effective staffing during floating holidays."}], roles:[{id:"r1", icon:hr_l, name:"HR", content:"Simplifies holiday planning and communication, fostering efficient workforce management"}, {id:"r2", icon:manager_l, name:"Managers", content:"Streamlines staffing planning with a centralized calendar and approval process for floating holidays."}, {id:"r3", icon:emp_l, name:"Employees", content:"Clear visibility of company-wide holidays and options for flexible time-off promote work-life balance and well-being."}]},
    {id:"d3", num:"03", tag:"#Approval Workflow Optimization", head:<h1 className="col-container-font h3-size m-b-10">Smooth Approval Journey: <span className="col-secondary">Streamlining</span> Leave Requests.</h1>, title:"Workflow Optimization", content:"Optimize leave approval processes to enhance operations.", img:workflowOptimization, points:[{id:"p1", point:"Streamlined leave request approval process based on your policy"}, {id:"p2", point:"Customize approval chains with multiple levels."}, {id:"p3", point:"Automated notifications keep everyone informed promptly."}, {id:"p4", point:"Accessible via mobile for convenient application."}, {id:"p5", point:"Enhances communication and speeds up decisions."}], roles:[{id:"r1", icon:hr_l, name:"HR", content:"Simplifies holiday planning and communication, leading to more efficient workforce management."}, {id:"r2", icon:manager_l, name:"Managers", content:"Streamlines staffing planning by using a centralized calendar and approval process for floating holidays."}, {id:"r3", icon:emp_l, name:"Employees", content:"Provides clear visibility of company-wide holidays and options for flexible time-off, promoting work-life balance and well-being."}]},
    {id:"d4", num:"04", tag:"#Detailed Leave Records and Insights", head:<h1 className="col-container-font h3-size m-b-10"> Informed Decision-Making: Unlocking Leave<span className="col-secondary"> Data Insights.</span></h1>, title:"Detailed Leave Records and Insights", content:"Gain insights and detailed records for informed decisions.", img:LeaveInsights, points:[{id:"p1", point:"Gain insights through employee leave analytics."}, {id:"p2", point:"Identify trends and manage absenteeism effectively."}, {id:"p3", point:"Maintain detailed records of leave balances"}, {id:"p4", point:"Track usage history and accurals accurately."}, {id:"p5", point:"Utilize comprehensive data for informed decisions."}], roles:[{id:"r1", icon:hr_l, name:"HR", content:"Empowers strategic workforce management through detailed leave data analysis."}, {id:"r2", icon:manager_l, name:"Managers", content:"Enhances staffing efficiency and resource allocation with centralized leave information."}, {id:"r3", icon:emp_l, name:"Employees", content:"Improves work-life balance with transparent leave visibility and flexible options."}]},
    // {id:"d5", num:"05", tag:"#Leave Year End Process", head:<h1 className="col-container-font h3-size m-b-10">Transition with Confidence: Effortless<span className="col-secondary"> Year-End </span>Leave Management.</h1>, title:"Leave Year End Process", content:"Ensure a smooth transition and accurate balance calculations.", img:holidayPlanning, points:[{id:"p1", point:"Automates year-end leave balance reconciliation tasks."}, {id:"p2", point:"Resets accruals and generates year-end reports."}, {id:"p3", point:"Facilitates carryover of leave balances."}, {id:"p4", point:"Allows employees to encash accrued leave."}], roles:[{id:"r1", icon:hr_l, name:"HR", content:"Efficiently reconciles leave balances and resets accruals for compliance."}, {id:"r2", icon:manager_l, name:"Managers", content:"Enhances resource planning with automated year-end reports and carryover tracking."}, {id:"r3", icon:emp_l, name:"Employees", content:"Provides clarity on leave encashment and carryover for personal planning."}]},
]


const SecureLeaveManage = () => {

    const {isOn} = useContext(MenuContext)

    return(
        <div className="d-flex flex-column secure-hub-cont align-self-center p-t-30 p-b-60">
            {data.map((each) => {
                 return <div style={{top:`-80px`}} className="m-t-30 cont" key={each.id}>
                        <span className="text-11 font-weight-6 rounded-5 bg-col-new-light-blue-200 p-1">{each.num}</span>
                        <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>{each.tag}</p>
                        {each.head}
                        <LeftArrow style={{rotate:"-90deg"}} className="m-b--10 move-arrow" />
                        <div className="d-flex flex-column border-left p-l-10">
                        <div className="d-flex flex-column m-t-30">
                            <div className="d-flex flex-row justify-content-between align-items-center hr-feature-cont">
                                <div className="d-flex flex-column inner-hr-feature-cont">
                                    <div className="d-flex flex-row align-items-center bg-col-new-light-yellow rounded-16 p-x-10 p-y-10 title-cont">
                                        <SlideIcon />
                                        <span className="m-l-10 font-weight-6">{each.title}</span>
                                    </div>
                                    <p className="font-1 p-size font-weight-4 m-l-10">{each.content}</p>
                                    <ul className="p-l-0 hr-hub-list m-l-10">
                                        {each.points.map(item => (
                                            <li className="p3-size col-points-clr font-1 font-weight-4 m-b-30 m-t-30" key={item.id}><BaseSvg2 isOn={isOn}><Point className="m-r-10" /></BaseSvg2> {item.point}</li>
                                        ))}
                                    </ul>
                                </div>
                                <img src={each.img} alt="Hr hub" className="leave-feature-img"/>
                            </div>
                            <div className="d-center flex-row m-t-30">
                                <LeftArrow width={40} height={40} style={{rotate:"180deg"}} className="m-l--30 bg-light-yellow rounded-20" />
                                <div className="d-flex flex-row justify-content-between align-items-start rounded-16 role-cont m-l--10">
                                    {each.roles.map(role => (
                                        <div key={role.id} className="d-flex flex-column role-child-cont">
                                            <span className="col-head-clr text-16 font-weight-4 role-op">{role.icon} {role.name}</span>
                                            <p className="col-head-clr font-1 p-size font-weight-4 mb-0 m-t-10">{role.content}</p>
                                        </div>
                                    ))}
                                </div>  
                            </div>
                        </div>
                    </div>
            </div>})}
        </div>
    )
}

export default SecureLeaveManage