
export const VERIFY_EMAIL = `
  query VerifyEmail($input: VerifyEmailInput!) {
    verifyEmail(input: $input) {
      success
    }
  }
`;

export const VERIFY_PHONE = `
  query($input: VerifyPhoneInput!) {
    verifyPhone(input:$input) {
      success
    }
  }
`;

export const VERIFY_DOMAIN = `
  query($input: VerifyDomainInput!) {
    verifyDomain(input:$input) {
      success
    }
  }
`;

export const INDUSTRIES = `
  query($paginationArgs:AdminPaginationArgs,$filter:String){
    adminIndustries(paginationArgs:$paginationArgs,filter:$filter){
        count,
        results{
            id,
            name
        }
    }
}
`;

export const ACCOUNT = `
  query($token : String!) {
    accountStatus(token:$token) {
        name,
        entity_name,
        industry,
        email,
        phone,
        location,
        ip,
        size,
        email_domain,
        fullname,
        domain,
        verified,
        setup
    }
}
`;

export const DOMAIN_LOGIN = `
  query ($input: PreLoginInput!) {
    preLogin(input: $input) {
      domains{
        url
      }
        id
    }
  }`;
  
export const VERIFY_ACCOUNT = `
  query($token : String!) {
  emailVerify(token:$token){
    success
  }
}
`;
