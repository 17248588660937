import React, { useState, useEffect } from 'react';
import './index.scss'
import { BsArrowUp } from "react-icons/bs";

const ScrollToTopButton = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [dashOffset, setDashOffset] = useState(307.919);

  const root = document.getElementById("root");

  useEffect(() => {
    const handleScroll = () => {
      if(root) {
        const position = root.scrollTop;
        const maxOffset = 307.919;
        const windowHeight = root.offsetHeight || 0;
        const documentHeight = Math.max(
          root.scrollHeight || 0,
          document.documentElement.scrollHeight || 0
        );
        const maxScroll = documentHeight - windowHeight;
        const scrollPercentage = position / maxScroll;
        setScrollPosition(position);
        setDashOffset(maxOffset * (1 - scrollPercentage));
      }
      
    };

    root?.addEventListener('scroll', handleScroll);
    return () => root?.removeEventListener('scroll', handleScroll);
  }, []);

  const backToTop = () => {
    const root = document.getElementById("root");
    if (root) {
      root.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  };

  return (
    
    <div className={scrollPosition > 200?'progress-wrap active-progress':'progress-wrap'} onClick={backToTop}>
      <svg
        className="progress-circle"
        width="100%"
        height="100%"
        viewBox="-1 -1 102 102"
      >
        <path
          d="M50,1 a49,49 0 0,1 0,98 a49,49 0 0,1 0,-98"
          style={{ strokeDashoffset: dashOffset, strokeDasharray:"307.919, 307.919", transition: "stroke-dashoffset 10ms linear 0s"}}
        ></path>
      </svg>
      <BsArrowUp className='mat-icon' style={{height: "17px", width:"17px"}} />
    </div>
  );
};

export default ScrollToTopButton;
