import "./index.scss"
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'
import {ReactComponent as SlideIcon} from '../../../../assets/logos/ess-slide-icon.svg'
import {ReactComponent as Point} from '../../../../assets/logos/point.svg'
import {ReactComponent as Hr} from '../../../../assets/logos/pink-hr.svg'
import {ReactComponent as Manager} from '../../../../assets/logos/pink-manager.svg'
import {ReactComponent as Emp} from '../../../../assets/logos/pink-employee.svg'
import { BaseSvg2 } from "../../../../styled-components"
import { useContext } from "react"
import { MenuContext } from "../../../../app"

import ShiftManagement from '../../../../assets/logos/ess-feat-1.svg'
import AttendanceCapture from '../../../../assets/logos/ess-feat-2.svg'
import Overtime from '../../../../assets/logos/ess-feat-3.svg'
import Ov from '../../../../assets/logos/ess-feat-4.svg'
import Remotework from '../../../../assets/logos/ess-feat-5.svg'
import TrackingPolicy from '../../../../assets/logos/ess-feat-6.svg'

const hr_a = <Hr id="hr_e"/>
const manager_a = <Manager id="hr_e"/>
const emp_a = <Emp id="hr_e"/>

const data = [
    {id:"d1", num:"01", tag:"#Mobile ESS Access", head:<h1 className="col-container-font h3-size m-b-10">Stay Connected, Stay Efficient: Mobile ESS Access for <span className="col-secondary">Real-Time Team Updates!</span></h1>, title:"Mobile ESS Access", content:"Perform self-service tasks and receive real-time team notifications from anywhere, ensuring you're always in the loop, even on the move", img:ShiftManagement,imgClassName:"img-s", points:[{id:"p1", point:"Perform self-service tasks and access information from any location."}, {id:"p2", point:"Stay informed about team leave and attendance in real time."}, {id:"p3", point:"Apply for leave conveniently through the mobile app."}, {id:"p4", point:"Punch in and out for attendance remotely using your device."}, {id:"p5", point:"View up-to-date information about team availability and statuses."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Enhanced flexibility in task management and improved communication with real-time updates."}, {id:"r2", icon:manager_a, name:"Managers", content:"Streamlined processes in leave management and attendance tracking, leading to reduced workload and cost savings."}, {id:"r3", icon:emp_a, name:"Employees", content:" Real-time oversight on team activities, facilitating quicker decision-making and efficient operations."}]},
    {id:"d2", num:"02", tag:"#Personal and Job Profile Management", head:<h1 className="col-container-font h3-size m-b-10">Empower <span className="col-secondary">Organizational Success</span> through Seamless Profile Management</h1>, title:"Personal and Job Profile Management", content:" Efficiently manage personal and job-related details, gain insights into team structures, and streamline operations for enhanced organizational effectiveness.", img:AttendanceCapture, imgClassName:"img-s", points:[{id:"p1", point:"Update personal and job details conveniently."}, {id:"p2", point:"Access hierarchical information for understanding team roles."}, {id:"p3", point:"Align personal aspirations with team objectives."}, {id:"p4", point:"Access real-time data for strategic career planning."}, {id:"p5", point:"Ensure data integrity with up-to-date details."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Streamlined profile management reduces administrative workload and ensures accurate reporting."}, {id:"r2", icon:manager_a, name:"Managers", content:"Clear reporting and team understanding enhance communication and decision-making."}, {id:"r3", icon:emp_a, name:"Employees", content:"Empowered employees with seamless updates increase satisfaction and retention."}]},
    {id:"d3", num:"03", tag:"#Leave Management", head:<h1 className="col-container-font h3-size m-b-10">Transform Your <span className="col-secondary">Leave Experience</span> with Mobile Ease and Efficiency</h1>, title:"Leave Management", content:"Access and manage your leave effortlessly using our mobile app. Streamline your time off requests for a seamless experience.", img:Overtime, imgClassName:"img-s", points:[{id:"p1", point:"Access and understand company leave policies and available types."}, {id:"p2", point:"Monitor leave balances and review leave usage history."}, {id:"p3", point:"Easily apply for leave directly through the mobile app."}, {id:"p4", point:"Managers can efficiently review and approve/reject leave requests."}, {id:"p5", point:"Receive updates and notifications on leave status and approvals."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Simplifies leave administration, improves compliance, and enhances workforce management."}, {id:"r2", icon:manager_a, name:"Managers", content:" Facilitates quick decision-making on leave requests, ensuring optimal team scheduling and productivity."}, {id:"r3", icon:emp_a, name:"Employees", content:"Provides convenient and accessible leave management, promoting work-life balance and employee satisfaction."}]},
    {id:"d4", num:"04", tag:"#Attendance Management", head:<h1 className="col-container-font h3-size m-b-10">Enhance Workforce Management with <span className="col-secondary">Mobile Attendance Control</span></h1>, title:"Attendance Management", content:"Navigate attendance policies and punch in/out on the go. Simplify attendance tracking and enhance workforce productivity with our mobile app.", img:Ov, imgClassName:"img-s", points:[{id:"p1", point:"View attendance guidelines and regulations."}, {id:"p2", point:"Manage your attendance from any location"}, {id:"p3", point:"Monitor regular, WFH, and overtime hours."}, {id:"p4", point:"Get updates on attendance status and policies."}, {id:"p5", point:"Maintain adherence to company attendance rules."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Streamline attendance tracking and ensure policy compliance effortlessly through the mobile app."}, {id:"r2", icon:manager_a, name:"Managers", content:" Monitor team attendance in real-time, review logs, and ensure accurate tracking of work hours and overtime."}, {id:"r3", icon:emp_a, name:"Employees", content:"Easily punch in/out and access attendance policies from anywhere, ensuring transparency and accuracy."}]},
    {id:"d5", num:"05", tag:"#Self Onboarding Process", head:<h1 className="col-container-font h3-size m-b-10"> Transform Onboarding with a <span className="col-secondary">Seamless Paperless Experience</span></h1>, title:"Self Onboarding Process", content:"Reduce paperwork and save time with a fully digital onboarding process. Complete onboarding tasks from anywhere, simplifying HR efforts and enhancing new hire experiences.", img:Remotework, points:[{id:"p1", point:"Eliminate physical paperwork."}, {id:"p2", point:"Save time for HR and new hires."}, {id:"p3", point:"Onboard from anywhere with the internet."}, {id:"p4", point:"Simplify and speed up onboarding steps."}, {id:"p5", point:"Minimize errors and discrepancies."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Streamline onboarding tasks and reduce administrative workload, making the process more efficient and error-free."}, {id:"r2", icon:manager_a, name:"Managers", content:"Ensure new hires are integrated smoothly and quickly, enabling them to start contributing to the team without delays."}, {id:"r3", icon:emp_a, name:"Employees", content:"Complete onboarding tasks remotely, making the process more convenient and less stressful, promoting a positive start."}]},
    {id:"d6", num:"06", tag:"#Comprehensive ESS Solution ", head:<h1 className="col-container-font h3-size m-b-10">  Simplify Employee Services with <span className="col-secondary">One Powerful Mobile App</span></h1>, title:"Comprehensive ESS Solution ", content:"Manage personal details, leave, and attendance effortlessly. Streamline your experience with a comprehensive mobile ESS solution.", img:TrackingPolicy, points:[{id:"p1", point:"Easily edit and view personal information."}, {id:"p2", point:"Apply for and track leave balances"}, {id:"p3", point:"Punch in/out and monitor attendance."}, {id:"p4", point:"View company policies and guidelines."}, {id:"p5", point:"Get real-time updates on leave and attendance."}], roles:[{id:"r1", icon:hr_a, name:"HR", content:"Simplify HR tasks with an all-in-one solution, reducing administrative burden and improving efficiency."}, {id:"r2", icon:manager_a, name:"Managers", content:"Easily monitor and approve leave requests and track team attendance, ensuring smooth team management."}, {id:"r3", icon:emp_a, name:"Employees", content:"Manage personal details, leave, and attendance from a single mobile app, making ESS tasks easy and accessible."}]},
]


const SecureAttendanceManage = () => {

    const {isOn} = useContext(MenuContext)

    return(
        <div className="d-flex flex-column secure-hub-cont align-self-center p-t-30 p-b-60">
            {data.map((each) => {
                 return <div style={{top:`-100px`}} className="m-t-30 cont" key={each.id}>
                        <span className="text-11 font-weight-6 rounded-5 bg-col-new-light-blue-200 p-1">{each.num}</span>
                        <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>{each.tag}</p>
                        {each.head}
                        <LeftArrow style={{rotate:"-90deg"}} className="m-b--10 move-arrow" />
                        <div className="d-flex flex-column border-left p-l-10">
                        <div className="d-flex flex-column m-t-30">
                            <div className="d-flex flex-row justify-content-between align-items-center hr-feature-cont">
                                <div className="d-flex flex-column inner-hr-feature-cont">
                                    <div className="d-flex flex-row align-items-center bg-col-ess-blue rounded-16 p-x-10 p-y-10 title-cont">
                                        <SlideIcon />
                                        <span className="m-l-10 font-weight-6">{each.title}</span>
                                    </div>
                                    <p className="font-1 p-size font-weight-4 m-l-10">{each.content}</p>
                                    <ul className="p-l-0 hr-hub-list m-l-10">
                                        {each.points.map(item => (
                                            <li className="p3-size col-points-clr font-1 font-weight-4 m-b-30 m-t-30" key={item.id}><BaseSvg2 isOn={isOn}><Point className="m-r-10" /></BaseSvg2>{item.point}</li>
                                        ))}
                                    </ul>
                                </div>
                                <img src={each.img} alt="Hr hub" className={each.imgClassName?each.imgClassName:"ess-feature-img"}/>
                            </div>
                            <div className="d-center flex-row m-t-30">
                                <LeftArrow width={40} height={40} style={{rotate:"180deg"}} className="m-l--30 bg-light-ess rounded-20" />
                                <div className="d-flex flex-row justify-content-between align-items-start rounded-16 role-cont m-l--10">
                                    {each.roles.map(role => (
                                        <div key={role.id} className="d-flex flex-column role-child-cont">
                                            <span className="col-head-clr text-16 font-weight-4 role-op">{role.icon} {role.name}</span>
                                            <p className="col-head-clr font-1 p-size font-weight-4 mb-0 m-t-10">{role.content}</p>
                                        </div>
                                    ))}
                                </div>  
                            </div>
                        </div>
                    </div>
            </div>})}
        </div>
    )
}

export default SecureAttendanceManage