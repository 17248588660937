import './index.scss'
import { useContext } from 'react'
import {MdOutlineKeyboardArrowRight } from "react-icons/md";
import CategorySlide from '../category-slide';
import { MenuContext } from '../../../app';

interface CategoryItem {
    category__id: string
    category__name: string
    category__is_clicked: boolean
}

const OurCategories = () => {

    const {blogList, setBlogList, isOn, setIsLoading} = useContext(MenuContext)

    const getBlogListByCategory = async(id:string) => {
        setIsLoading(true)
        const url = `${process.env.REACT_APP_BASE_URL}/blogs?category=${id}`;

        const options = {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            }
        }  

        try{
            const response = await fetch(url, options)
            if(response.ok){
                setIsLoading(false)
                const blogs = await response.json()
                setBlogList(blogs);
                if(id !== ''){
                    setBlogList((prevBlogList:any) => ({
                        ...prevBlogList,
                        categories: prevBlogList.categories.map((category:any) => {
                        if (category.category__id === id) {
                            return { ...category, category__is_clicked: !category.category__is_clicked };
                        } else {
                            return category;
                        }
                        })
                    }));
                }else{
                    setBlogList((prevBlogList:any) => ({
                        ...prevBlogList,
                        categories: prevBlogList.categories.map((category:any) => {
                        if (category.category__name === "All") {
                            return { ...category, category__is_clicked: !category.category__is_clicked };
                        } else {
                            return category;
                        }
                        })
                    }));
                }
            }
        }
        catch(error:any){
            console.error(`error in fetching blogs: ${error.message}`)
        }
    }

    const filterBlogsByCategory = (id:string) => {
        getBlogListByCategory(id)
    }

    return(
        <div className='our-category-cont align-self-center d-flex flex-column align-items-start m-t-50 p-b-40'>
            <h1 className="col-container-font h2-size m-b-30">Our <span className='col-secondary'>Categories</span></h1>
            {blogList.categories.length <= 5 && <div className='d-flex flex-row flex-wrap'>
                {blogList.categories.map((each:CategoryItem) => {
                    return <div style={{backgroundColor:isOn?each.category__is_clicked? "#1A2D48":"#FFFFFF":each.category__is_clicked? "#F45600":"#FFFFFF", color:each.category__is_clicked? "#ffffff":"#1F3549"}} onClick={() => filterBlogsByCategory(each.category__id)} key={each.category__id} className='d-flex flex-row justify-content-between align-items-center rounded-3 category-box'>
                        <span className='text-12 font-weight-6 font-1'>{each.category__name}</span>
                        <MdOutlineKeyboardArrowRight />
                    </div>
                })}
            </div>}
            {blogList.categories.length > 5 && <CategorySlide categories={blogList.categories} filterBlogsByCategory={filterBlogsByCategory} />}
        </div>
    )
}

export default OurCategories