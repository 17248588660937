import React from 'react'
import {ReactComponent as Astronut} from '../../../assets/logos/astronut-product.svg'

import './index.scss'

const ProductMenuCard = () => {
  return (
    <div className="product-menu-card d-between flex-row align-self-center m-t-10">
        <div className="cont-card d-flex flex-column">
            <p className="col-quaternary font-weight-5 text-12 m-b-0 m-t-0 text-uppercase cent">
                A cloud HRMS platform
            </p>
            <h3 className="col-container-font h2-size cent center">
                Transform Your HR Management Today with <span className='col-secondary'> Walkzero</span>
            </h3>
        </div>
        <Astronut height={140} className='hide-mb' />
    </div>
  )
}

export default ProductMenuCard