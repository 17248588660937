import "./index.scss"
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'
import {ReactComponent as SlideIcon} from '../../../../assets/logos/prod-slide-icon.svg'
import {ReactComponent as Point} from '../../../../assets/logos/point.svg'
import {ReactComponent as Hr} from '../../../../assets/logos/hr-team.svg'
import {ReactComponent as Manager} from '../../../../assets/logos/manager.svg'
import {ReactComponent as Emp} from '../../../../assets/logos/employee.svg'
import { BaseSvg2 } from "../../../../styled-components"
import { useContext } from "react"
import { MenuContext } from "../../../../app"

import EmpDatabase from '../../../../assets/logos/hr-feat-1.svg'
import OrganizationChart from '../../../../assets/logos/hr-feat-2.svg'
import OrgPolicies from '../../../../assets/logos/hr-feat-3.svg'
import BulkUpload from '../../../../assets/logos/hr-feat-4.svg'
import EmpManagement from '../../../../assets/logos/hr-feat-5.svg'

const data = [
    {id:"d1", num:"01", tag:"#Employee Database ", head:<h1 className="col-container-font h3-size m-b-10">Centralize Your Team's Vital Info: <span className="col-secondary">One Secure Hub</span> for HR Management</h1>, title:"Employee Database", content:"Efficiently store and manage employee information, streamlining HR tasks.", img:EmpDatabase, points:[{id:"p1", point:"End-to-end HR data accessibility, from recruitment to retirement."}, {id:"p2", point:"Centralized data access for effortless information retrieval."}, {id:"p3", point:"Track employee portal usage status with ease."}, {id:"p4", point:"Customize data views for tailored insights and analysis."}, {id:"p5", point:"Efficiently save incomplete employee entries for future completion."}], roles:[{id:"r1", icon:<Hr />, name:"HR", content:"Centralized employee data management for efficient HR operations, ensuring accurate records and compliance with organizational standards."}, {id:"r2", icon:<Manager />, name:"Managers", content:"Access to comprehensive employee profiles for informed decision-making, such as performance reviews, skill assessments, and team planning."}, {id:"r3", icon:<Emp />, name:"Employees", content:"Convenient access to personal information and the ability to update details, promoting transparency and accuracy in employee records."}]},
    {id:"d2", num:"02", tag:"#Organisation Chart", head:<h1 className="col-container-font h3-size m-b-10">Mapping Growth, Fostering Unity: Walkzero's <span className="col-secondary">Hierarchical</span> Harmony</h1>, title:"Organisation Chart", content:"Visualize team structures for improved communication and collaboration.", img:OrganizationChart, points:[{id:"p1", point:"Effortlessly simplify reporting and departmental structures."}, {id:"p2", point:"Seamlessly manage employee bands and designations."}, {id:"p3", point:"Integrate divisions, units under one platform."}, {id:"p4", point:"Streamline operations across various legal entities."}, {id:"p5", point:"Gain comprehensive control with unified management"}], roles:[{id:"r1", icon:<Hr />, name:"HR", content:"Clear visualization of organizational structure facilitates effective workforce planning, succession planning, and talent management."}, {id:"r2", icon:<Manager />, name:"Managers", content:"Understanding of reporting relationships and team structure aids in delegation, coordination, and team development."}, {id:"r3", icon:<Emp />, name:"Employees", content:"Improved understanding of company hierarchy and team dynamics, fostering collaboration and alignment with organizational goals."}]},
    {id:"d3", num:"03", tag:"#Organisation Policies", head:<h1 className="col-container-font h3-size m-b-10">Policy Perfection: <span className="col-secondary">Customize </span>with Walkzero, Your HR Partner</h1>, title:"Organisation Policies", content:"Maintain compliance and clarity with easily accessible company guidelines.", img:OrgPolicies, points:[{id:"p1", point:"Customize policies for specific employee groups"}, {id:"p2", point:"Diverse attendance policy for varied workforces"}, {id:"p3", point:"Flexibility with 30-40+ policy settings"}, {id:"p4", point:"Tailor settings to meet specific needs"}, {id:"p5", point:"Ideal for multi-location and hybrid teams"}], roles:[{id:"r1", icon:<Hr />, name:"HR", content:"Centralized policy management ensures consistent communication, compliance, and adherence to company guidelines across the organization."}, {id:"r2", icon:<Manager />, name:"Managers", content:"Effective enforcement and communication of policies within their teams, promoting a cohesive and compliant work environment."}, {id:"r3", icon:<Emp />, name:"Employees", content:"Clear awareness of company rules and expectations, fostering a positive work culture and alignment with organizational values."}]},
    {id:"d4", num:"04", tag:"#Employee Management", head:<h1 className="col-container-font h3-size m-b-10">New Hires?  No Hassle! <span className="col-secondary">Onboard in Minutes:</span> Walkzero's Intuitive Self-Service</h1>, title:"Employee Management", content:"Seamlessly oversee employee schedules and performance for enhanced productivity.", img:EmpManagement, points:[{id:"p1", point:"All-in-one storage for onboarding to offboarding"}, {id:"p2", point:"Capture personal, education, leave, payroll data"}, {id:"p3", point:"Update employee details at centralized profile"}, {id:"p4", point:"Optimize activities with customizable dashboards"}, {id:"p5", point:"Access overview and past data effortlessly"}], roles:[{id:"r1", icon:<Hr />, name:"HR", content:"Streamlined onboarding, performance management, and leave tracking processes enhance HR efficiency and employee satisfaction."}, {id:"r2", icon:<Manager />, name:"Managers", content:"Efficient performance reviews, onboarding support, and leave management tools enable effective team leadership and development."}, {id:"r3", icon:<Emp />, name:"Employees", content:"Smooth onboarding, transparent performance assessments, and convenient leave requests contribute to employee engagement and productivity."}]},
    {id:"d5", num:"05", tag:"#Bulk Data Imports", head:<h1 className="col-container-font h3-size m-b-10">Expand Your Team, <span className="col-secondary">Simplify Your Steps:</span> Walkzero's Bulk Add</h1>, title:"Bulk Upload Addition", content:"Simplify onboarding by quickly uploading multiple employee records at once.", img:BulkUpload, points:[{id:"p1", point:"Optimize workflow with streamlined bulk data imports."}, {id:"p2", point:"Enhance productivity by minimizing time and effort."}, {id:"p3", point:"Seamlessly manage significant data volumes for efficiency."}, {id:"p4", point:"Ensure comprehensive records for improved data management."}, {id:"p5", point:"Simplify historical data migration for enhanced record-keeping."}], roles:[{id:"r1", icon:<Hr />, name:"HR", content:"Accelerated recruitment processes and scalable workforce management support organizational growth and expansion."}, {id:"r2", icon:<Manager />, name:"Managers", content:"Quick integration of new team members enables seamless team expansion and enhanced collaboration."}, {id:"r3", icon:<Emp />, name:"Employees", content:"Facilitated team growth ensures adequate support and resources for existing team members, fostering a cohesive and dynamic work environment."}]},
]


const SecureHub = () => {

    const {isOn} = useContext(MenuContext)

    return(
        <div className="d-flex flex-column secure-hub-cont align-self-center p-t-30 p-b-60">
            {data.map((each) => {
                 return <div style={{top:`-80px`}} className="m-t-30 cont" key={each.id}>
                        <span className="text-11 font-weight-6 rounded-5 bg-col-new-light-blue-200 p-1">{each.num}</span>
                        <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>{each.tag}</p>
                        {each.head}
                        <LeftArrow style={{rotate:"-90deg"}} className="m-b--10 move-arrow" />
                        <div className="d-flex flex-column border-left p-l-10">
                        <div className="d-flex flex-column m-t-30">
                            <div className="d-flex flex-row justify-content-between align-items-center hr-feature-cont">
                                <div className="d-flex flex-column justify-content-center inner-hr-feature-cont">
                                    <div className="d-flex flex-row align-items-center bg-col-new-light-green rounded-16 p-x-10 p-y-10 title-cont">
                                        <SlideIcon />
                                        <span className="m-l-10 font-weight-6">{each.title}</span>
                                    </div>
                                    <p className="font-1 p-size font-weight-4 m-l-10">{each.content}</p>
                                    <ul className="p-l-0 hr-hub-list m-l-10">
                                        {each.points.map(item => (
                                            <li className="p3-size col-points-clr font-1 font-weight-4 m-b-30 m-t-30" key={item.id}><BaseSvg2 isOn={isOn}><Point className="m-r-10" /></BaseSvg2> {item.point}</li>
                                        ))}
                                    </ul>
                                </div>
                            <img src={each.img} alt="Hr hub" className="hr-feature-img"/>    
                            </div>
                            <div className="d-center flex-row m-t-30">
                                <LeftArrow width={40} height={40} style={{rotate:"180deg"}} className="m-l--30 bg-light-green rounded-20" />
                                <div className="d-flex flex-row align-items-start justify-content-between rounded-16 role-cont m-l--10">
                                    {each.roles.map(role => (
                                        <div key={role.id} className="d-flex flex-column role-child-cont">
                                            <span className="col-head-clr text-16 font-weight-4 role-op">{role.icon} {role.name}</span>
                                            <p className="col-head-clr font-1 p-size font-weight-4 mb-0 m-t-10">{role.content}</p>
                                        </div>
                                    ))}
                                </div>  
                            </div>
                        </div>
                    </div>
            </div>})}
        </div>
    )
}

export default SecureHub