import './index.scss'
import CEO from '../../../assets/images/anitha.png'
import COO from '../../../assets/images/maha.png'
import CMO from '../../../assets/images/mani.png'
import CTO from '../../../assets/images/stephen.png'
import {ReactComponent as Circle} from "../../../assets/logos/circle.svg"
import { Tooltip } from "@mui/material";
import {useAnimation, motion} from "framer-motion"
import { useInView } from "react-intersection-observer";
import { useEffect } from 'react';
import { container2, child } from '../../../variants'


const LeaderShip = () => {

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])

    return(
        <motion.div ref={ref} variants={container2} initial="hidden" animate={ctrls} className='d-flex flex-column align-items-center align-self-center leadership-cont p-t-60 p-b-60 position-relative'>
            <motion.p variants={child} className="col-quaternary font-weight-5 text-12 mb-1 text-center text-uppercase">Guiding Today, Shaping Tomorrow</motion.p>
            <motion.h1 variants={child} className="col-container-font h2-size mb-0">Meet Our <span className='col-secondary'>Leadership Team</span></motion.h1>
            <motion.p variants={child} className='font font-weight-3 p-size text-center hide-mb'>Meet the visionary leaders at the helm of Walkzero. Our leadership team combines decades of expertise in technology, management, and industry-specific knowledge to steer our company towards unprecedented growth and innovation. Committed to excellence and driven by a passion for transformative solutions, each member brings a unique set of skills and a shared vision for the future of workplace technology. They are not just executives but pioneers who foresee market trends, inspire their teams, and cultivate environments where creative ideas thrive.</motion.p>
            <motion.div variants={child} className='leaders d-flex flex-row justify-content-between align-items-center'>
                <Tooltip title="Click to view profile" arrow><a href="https://www.linkedin.com/in/anithastephen/" target='_blank' rel="noreferrer" className='leader-img'><img src={CEO} alt="CEO" className='w-100'/></a></Tooltip>
                <Tooltip title="Click to view profile" arrow><a href="https://www.linkedin.com/in/maha-lakshmi-a0a15065/" target='_blank' rel="noreferrer" className='leader-img'><img src={COO} alt="COO" className='w-100'/></a></Tooltip>
                <Tooltip title="Click to view profile" arrow><a href="https://www.linkedin.com/in/stephen-sebastian-0a837352/" target='_blank' rel="noreferrer" className='leader-img'><img src={CTO} alt="CTO" className='w-100'/></a></Tooltip>
            </motion.div>
            <Circle width={150} className="position-absolute bottom-percentage--18 right-0 z-index-0" />
        </motion.div>
    )
}

export default LeaderShip