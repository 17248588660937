import './index.scss'
import {ReactComponent as Ownership} from "../../../assets/logos/ownership.svg"
import {ReactComponent as Integrity} from "../../../assets/logos/integrity.svg"
import {ReactComponent as Pursuit} from "../../../assets/logos/pursuit.svg"
import {ReactComponent as Inclusivity} from "../../../assets/logos/inclusivity.svg"
import {ReactComponent as Agility} from "../../../assets/logos/agility.svg"
import {ReactComponent as Innovation} from "../../../assets/logos/innovation.svg"
import {ReactComponent as SemiCircle} from "../../../assets/logos/about-us-curve.svg"
import {ReactComponent as W} from "../../../assets/logos/w.svg"
import {ReactComponent as LightCircle} from "../../../assets/logos/light-circle.svg"
import OurCulture from '../our-culture'
import {useAnimation, motion} from "framer-motion"
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { container } from '../../../variants'
import { MenuContext } from '../../../app'
import { useContext } from 'react'

const data = [
    {id:"value1", name:"Integrity", icon:<Integrity />, content:"We champion transparency and honesty in every interaction, ensuring our actions always reflect our ethical commitments."},
    {id:"value2", name:"Innovation", icon:<Innovation />, content:"Pioneering isn't just an activity, but our way of life. We thrive on creativity and adaptability, continuously pushing the boundaries to transform the industry."},
    {id:"value3", name:"Agility", icon:<Agility />, content:" We move fast, adapt quickly, and pivot when necessary. Staying responsive to both challenges and opportunities ensures we always lead from the front."},
    {id:"value4", name:"Inclusivity", icon:<Inclusivity />, content:"We value diverse perspectives and foster a culture where everyone feels seen, heard, and empowered to contribute."},
    {id:"value5", name:"Pursuit of Excellence", icon:<Pursuit />, content:"We’re obsessed with quality, not just in our products but in  every aspect of our operations. Striving for excellence is what drives us to deliver exceptional value consistently."},
    {id:"value6", name:"Ownership", icon:<Ownership />, content:"We empower every team member to think and act like an owner, making decisions that support our long-term vision and commitment to customers and each other."},
]

export type CultureProps = {
    culture:{
        id: string
        photo: string
        order: number
    }[]
}

const OurValues = (props:CultureProps) => {

    const {culture} = props
    const {isOn} = useContext(MenuContext)

    const ctrls = useAnimation();

    const { ref, inView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
      });

    useEffect(() => {
        if (inView) {
            ctrls.start("visible");
        }
        if (!inView) {
            ctrls.start("hidden");
        }
    }, [ctrls, inView])


    const child = {
        visible:{
            opacity:1,
            transition:{
                duration:1, 
                ease:"easeIn",
                staggerChildren: 0.2,
                delayChildren: 0.1,
            }
        },
        hidden:{
            opacity:0
        }
    }

    const child1 = {
        visible: {
            y:0,
            transition:{
                duration:0.3, 
                type:"tween",
                ease:"easeIn",
            }
        },
        hidden: {
            y:80
        }
    }

    return(
        <motion.div ref={ref} variants={container} initial="hidden" animate={ctrls} className='w-100 d-flex flex-column bg-col-mixed-white-blue p-t-60 p-b-60 position-relative'>
            <div className='d-flex flex-column align-items-center align-self-center our-values-cont z-index-1'>
                <motion.div variants={child} className='d-flex flex-column our-values'>
                    <p className="col-quaternary font-weight-5 text-12 mb-1 text-uppercase">Building Excellence Together</p>
                    <h1 className="col-container-font h2-size mb-0">Our <span className='col-secondary'>Values</span></h1>
                    <p className='font font-weight-3 p-size hide-mb'>At Walkzero, we are guided by a set of core values that shape our culture, our decisions, and our actions. These values reflect our commitment to not only achieving business excellence but also fostering a supportive and sustainable environment where both our employees and our communities can thrive. Our values are the foundation upon which we build our future, innovate our products, and maintain integrity in every aspect of our business.</p>
                </motion.div>
                <div className='d-flex flex-column security-cont m-t-20'>
                    <motion.div variants={child} className='d-flex flex-row justify-content-between align-items-start align-self-center inner-security-cont'>
                        {data.map(each => {
                            return <motion.div variants={child1} key={each.id} className='d-flex flex-column align-items-start bg-col-white p-3 rounded-20 value-card'>
                                {each.icon}
                                <span style={{color:isOn?"#1A2D48":"#F45600"}} className='font text-16 font-weight-6'>{each.name}</span>
                                <p className='font font-weight-3 p-size'>{each.content}</p>
                            </motion.div>
                        })}
                    </motion.div>
                </div>
            </div>
            {culture.length > 0 && <OurCulture culture={culture}/>}
            <SemiCircle className="position-absolute top--100 left-0 z-index-0" /> 
            <W className="position-absolute bottom--40 left-0 z-index-0" />
            <LightCircle className="position-absolute top-percentage--13 right-60 z-index-0" />
        </motion.div>
    )
}

export default OurValues