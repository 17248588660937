import "./index.scss"
import {ReactComponent as LeftArrow} from '../../../../assets/logos/hr-left-arrow.svg'
import {ReactComponent as SlideIcon} from '../../../../assets/logos/geo-slide-icon.svg'
import {ReactComponent as Point} from '../../../../assets/logos/point.svg'
import {ReactComponent as Hr} from '../../../../assets/logos/pink-hr.svg'
import {ReactComponent as Manager} from '../../../../assets/logos/pink-manager.svg'
import {ReactComponent as Emp} from '../../../../assets/logos/pink-employee.svg'
import { BaseSvg2 } from "../../../../styled-components"
import { useContext } from "react"
import { MenuContext } from "../../../../app"

import GeoFence from '../../../../assets/logos/geo-feat-1.svg'
import IntervalTracking from '../../../../assets/logos/geo-feat-3.svg'
import ContinuosTracking from '../../../../assets/logos/geo-feat-2.svg'

const hr_g = <Hr id="hr_g"/>
const manager_g = <Manager id="hr_g"/>
const emp_g = <Emp id="hr_g"/>

const data = [
    {id:"d1", num:"01", tag:"#Geo-Fence", head:<h1 className="col-container-font h3-size m-b-10">Seamless Clock-Ins with Virtual Boundaries.</h1>, title:"Geo-Fence", content:"Set up virtual boundaries to manage employee locations, ensuring precise clock-ins and automatic adjustments based on presence within geofences.", img:GeoFence, points:[{id:"p1", point:"Set up custom geofences for work sites."}, {id:"p2", point:"Ensure clock-ins within set boundaries."}, {id:"p3", point:"Automate clock-outs on fence exit."}, {id:"p4", point:"Map employees to multiple geofences."}, {id:"p5", point:"Manage attendance with virtual boundaries."}, {id:"p6", point:"Track location-specific employee attendance."}], roles:[{id:"r1", icon:hr_g, name:"HR", content:"Streamlined attendance management, minimizing manual tracking errors."}, {id:"r2", icon:manager_g, name:"Managers", content:"Enhanced oversight with real-time employee location tracking."}, {id:"r3", icon:emp_g, name:"Employees", content:"Convenient, automatic attendance within set geofence boundaries."}]},
    {id:"d2", num:"02", tag:"#Interval Tracking", head:<h1 className="col-container-font h3-size m-b-10">Precision Tracking, <span className="col-secondary">Seamless Connectivity</span>, Anywhere Workforce.</h1>, title:"Interval Tracking", content:"Effortlessly monitor employee attendance with interval-based tracking, ensuring accurate records for remote teams.", img:IntervalTracking, points:[{id:"p1", point:"Capture employee attendance at scheduled intervals."}, {id:"p2", point:"Address internet issues to ensure consistent data syncing."}, {id:"p3", point:"Ideal for remote teams, ensuring location compliance."}, {id:"p4", point:"Track employee whereabouts at predetermined times."}, {id:"p5", point:"Receive real-time data updates for accurate records."}], roles:[{id:"r1", icon:hr_g, name:"HR", content:"Streamline attendance tracking for remote teams, ensuring compliance and accuracy."}, {id:"r2", icon:manager_g, name:"Managers", content:"Easily monitor employee whereabouts and ensure productivity during work hours."}, {id:"r3", icon:emp_g, name:"Employees", content:"Experience seamless attendance tracking, reducing manual effort and ensuring fair compensation."}]},
    {id:"d3", num:"03", tag:"#Continuos Tracking", head:<h1 className="col-container-font h3-size m-b-10">Unlock Efficiency, Empower Accountability, and Navigate Success Together.</h1>, title:"Continuos Tracking", content:"Maximize productivity with live monitoring, route tracking, and effortless meeting scheduling, empowering teams for unparalleled success.", img:ContinuosTracking, points:[{id:"p1", point:"Suited for sales, field service, remote teams."}, {id:"p2", point:"Ensures safety during work hours."}, {id:"p3", point:"Monitors travel paths for safety."}, {id:"p4", point:"Enhances accountability throughout the workday."}, {id:"p5", point:"Effortless Salesforce team meeting organization."}, {id:"p6", point:"Simplifies scheduling with comprehensive details."}], roles:[{id:"r1", icon:hr_g, name:"HR", content:"Effortlessly track employee attendance, ensuring accurate records and eliminating manual errors for smoother payroll processing."}, {id:"r2", icon:manager_g, name:"Managers", content:"Gain real-time insights into employee whereabouts, enabling better task allocation and ensuring timely project completion."}, {id:"r3", icon:emp_g, name:"Employees", content:"Enjoy hassle-free attendance tracking, providing flexibility in work arrangements and reducing administrative burdens."}]},
]

const SecureGeoFenceManage = () => {

    const {isOn} = useContext(MenuContext)

    return(
        <div className="d-flex flex-column secure-hub-cont align-self-center p-t-30 p-b-60">
            {data.map((each) => {
                 return <div style={{top:`-80px`}} className="m-t-30 cont" key={each.id}>
                        <span className="text-11 font-weight-6 rounded-5 bg-col-new-light-blue-200 p-1">{each.num}</span>
                        <p className='text-12 col-quaternary font-weight-5 text-12 mb-1 text-uppercase'>{each.tag}</p>
                        {each.head}
                        <LeftArrow style={{rotate:"-90deg"}} className="m-b--10 move-arrow" />
                        <div className="d-flex flex-column border-left p-l-10">
                        <div className="d-flex flex-column m-t-30">
                            <div className="d-flex flex-row justify-content-between align-items-center hr-feature-cont">
                                <div className="d-flex flex-column inner-hr-feature-cont">
                                    <div className="d-flex flex-row align-items-center bg-col-container-bg rounded-16 p-x-10 p-y-10 title-cont">
                                        <SlideIcon />
                                        <span className="m-l-10 font-weight-6">{each.title}</span>
                                    </div>
                                    <p className="font-1 p-size font-weight-4 m-l-10">{each.content}</p>
                                    <ul className="p-l-0 hr-hub-list m-l-10">
                                        {each.points.map((item:any) => (
                                            <li className="p3-size col-points-clr font-1 font-weight-4 m-b-30 m-t-30" key={item.id}><BaseSvg2 isOn={isOn}><Point className="m-r-10" /></BaseSvg2> {item.point}</li>
                                        ))}
                                    </ul>
                                </div>
                                <img src={each.img} alt="Hr hub" className="geo-feature-img"/>
                            </div>
                            <div className="d-center flex-row m-t-30">
                                <LeftArrow width={40} height={40} style={{rotate:"180deg"}} className="m-l--30 bg-light-orange rounded-20" />
                                <div className="d-flex flex-row justify-content-between align-items-start rounded-16 role-cont m-l--10">
                                    {each.roles.map((role:any) => (
                                        <div key={role.id} className="d-flex flex-column role-child-cont">
                                            <span className="col-head-clr text-16 font-weight-4 role-op">{role.icon} {role.name}</span>
                                            <p className="col-head-clr font-1 p-size font-weight-4 mb-0 m-t-10">{role.content}</p>
                                        </div>
                                    ))}
                                </div>  
                            </div>
                        </div>
                    </div>
            </div>})}
        </div>
    )
}

export default SecureGeoFenceManage