import './index.scss'
import {motion} from 'framer-motion'
import { BaseBtn } from '../../../styled-components'
import { MenuContext } from "../../../app"
import { useContext } from "react"


const ClockIn = () => {

    const props = useContext(MenuContext);
    const {isOn} = props

    const ball = {
        move:{
            x:[50, 0],
            transition:{
                type:"spring",
                bounce:0.2,
                duration:2
            }
        },
        hold:{
            x:0,
        }
    }

    return(
        <div className="w-70 bg-col-white rounded-25 p-x-20 p-y-10 m-2 clock-in">
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <p className='font-weight-3 text-12 col-black font-1'>Web Clock In <span className='text-10 op'>shift 09:00am to 6:00pm</span></p>
                <p className='font-weight-5 text-12 col-black font-1'>11:10am</p>
            </div>
            <div className='d-flex flex-row justify-content-between align-items-center'>
                <div className='d-flex flex-column'>
                    <span className='font-1 text-13 op'>14 Apr Monday</span>
                    <div className='d-flex'>
                        <span className='font-1 text-13 m-r-30 op'>9:05am</span>
                        <span className='font-1 text-13 op'>----</span>
                    </div>
                    <div className='d-flex flex-row justify-content-start align-items-center w-80'>
                        <motion.div style={{backgroundColor:isOn?"#1A2D48":"#F45600"}} variants={ball} initial='hold' animate='move' className='ball'></motion.div>
                        <hr style={{borderColor:isOn?"#1A2D48":"#F45600"}} className='line w-100'/>
                    </div>
                </div>
                <BaseBtn isOn={isOn} className='text-12 rounded-8 clock-in-btn col-white p-1 text-12 font-weight-5' type="button">Clock Out</BaseBtn>
            </div>
        </div>
    )
}

export default ClockIn