import "./index.scss"
import { Link, useParams } from "react-router-dom"
import {ReactComponent as Logo} from "../../../assets/logos/logo.svg"
import {ReactComponent as Illustration} from "../../../assets/logos/illustration.svg"
import { data2 } from "../../product-menu/product-menu";
import { useContext, useEffect, useState } from "react";
import { MenuContext } from "../../../app";
import {ReactComponent as Quotes} from "../../../assets/logos/quotes.svg"
import Avatar from "../../../assets/images/avatar.png"
import {ReactComponent as Star} from '../../../assets/logos/star.svg'
import {ReactComponent as Tick} from '../../../assets/logos/tick.svg'
import {ReactComponent as Close} from '../../../assets/logos/close-popup.svg'
import { ACCOUNT } from "../../../graphql/queries";
import { ACTIVATE_ACCOUNT } from "../../../graphql/mutation";
import { TailSpin } from "react-loader-spinner";

const url = `${process.env.REACT_APP_WZ_URL}`;

const initialData = {
    email:'',
    name:'',
    fullname:'',
    phone:'',
    industry:'',
    domain:'',
    size:''
}

const status = {
    initial:"Initial",
    inProgress:"Progress",
    success:"Success",
    failed:"Failed"
}

const ActivateAccount = () => {

    const {setIsOpen, setIsProductOpen, setModules} = useContext(MenuContext)
    const params = useParams()
    const token = params.id
    
    const [account, setAccount] = useState(initialData)  
    const [isActive, setActive] = useState(false)
    const [msg, setMsg] = useState('')
    const [show, setShow] = useState(false)
    const [progress, setProgress] = useState(status.initial)

    const clickLogo = () => {
        setIsOpen(false)
        setIsProductOpen(false)
        setModules(data2)
    }

    const getAccount = async() => {
        setProgress(status.inProgress)
        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: ACCOUNT,
                variables: { token },
                }),
            })

            if (response.ok) {
                
                const res = await response.json();
                const {data, errors} = res
                if(data){
                    setProgress(status.success)
                    setAccount(data.accountStatus)
                }
                if(errors){
                    setProgress(status.success)
                    setActive(true)
                    setMsg(errors[0].token[0])
                }
                return res;
            }
        }
        catch(error:any){
            console.error(`error in signup: ${error.message}`)
        }    
    }

    const activate = async() => {

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                query: ACTIVATE_ACCOUNT,
                variables: { token },
                }),
            })

            if (response.ok) {
                
                const res = await response.json();
                const {data, errors} = res
                if(data){
                    console.log(data)
                    if(data.activateAccount.success){
                        setActive(true)
                        setMsg('Account is Activated')
                    }
                    setShow(false)
                }
                if(errors){
                    setShow(false)
                    setActive(true)
                    setMsg(errors[0].token[0])
                }
                return res;
            }
        }
        catch(error:any){
            console.error(`error in signup: ${error.message}`)
        }    
    }

    const showPopup = () => {
        setShow(prevShow => !prevShow)
    }

    const closePopup = () => {
        setShow(false)
    }

    useEffect(() => {
        getAccount()
    }, [])

    const {fullname, domain, email, industry, name, size, phone} = account

    return(
        <>
        <div className='w-100 d-flex flex-row justify-content-start align-self-center signup-wz-logo'>
            <Link to="/" onClick={clickLogo}><Logo width={"73%"} className="logo-height m-t-30"/></Link>
        </div>
        <div style={{minHeight:isActive?"90vh":"auto"}} className="w-100 grad d-flex flex-row align-items-start position-relative">
            <div style={{filter:show?"blur(3px)":''}} className="d-flex flex-column align-items-center bg-col-white sign-up-first-cont1 p-b-10">
                <div className="position-relative d-flex flex-row justify-content-center ellipse m-t-40 w-70">
                    <Illustration />
                </div>
                <><div className='rounded-21 d-flex flex-row justify-content-start align-items-center align-self-start avatar-cont bod m-t-60'>
                    <img className='z-index-1 m-r-20 m-l--5' src={Avatar} alt="avatar" />
                    <div>
                        <p className='text-12 m-b-0 text-center'>Rajasekaran Arul</p>
                        <p className='font font-weight-3 text-12 m-t-0 text-center'>GM / Page Industries</p>
                    </div>
                </div>
                <div className='p-3 rounded-15 bg-col-white d-flex flex-column align-items-center m-t-10 w-70'>
                    <h3 className='font font-weight-6 text-12 text-center'>Amazing product with cutting-edge interface and optimized user flow!</h3>
                    <Quotes className="align-self-start" />
                    <p className='font font-weight-3 text-12 align-self-center'>Walkzero has revolutionized the way we handle HR tasks, simplifying complexities and significantly reducing time investments. Its user-friendly interface and comprehensive features have made managing our workforce a breeze, resulting in increased productivity and efficiency across the board.</p>
                    <Quotes style={{rotate:"180deg"}} className="align-self-end m-t--10"/>
                </div></>
            </div>
            <div style={{justifyContent:isActive?"center":"start", filter:show?"blur(3px)":''}} className="position-relative sign-up-second-cont1 bg-col-container-bg d-flex flex-column p-t-30 p-b-10">
                {progress === status.inProgress  && <div className="align-self-center"><TailSpin  visible={true} height="30" width="30" color="#f45600" ariaLabel="tail-spin-loading" radius="1" /></div>}
                {progress === status.success && <><div className="d-flex flex-column align-items-center account-cont align-self-center position-relative">
                    {isActive && <h2 className="col-secondary text-22 mb-0 align-self-center m-t-10 text-center">{msg}</h2>}
                    {!isActive &&<>
                    <h2 className="col-container-font text-22 mb-0 align-self-start m-t-10 m-l-15">Account <span className='col-secondary'>Activation</span></h2>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-15">Owner Name</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{fullname}</span>
                    </div>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Work email ID</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{email}</span>
                    </div>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Phone Number</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{phone}</span>
                    </div>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Company Name</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{name}</span>
                    </div>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Industry Type</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{industry}</span>
                    </div>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Employee Count</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{size}</span>
                    </div>
                    <span className="m-b--5 font-1 font-weight-3 text-10 label-clr align-self-start z-index-1 m-l-20 m-t-20">Preferred Domain</span>
                    <div className="label-box">
                        <span className="font-1 text-13 font-weight-3 label-clr">{domain}</span>
                    </div>
                    <Star className="position-absolute big-star" />
                    <Star style={{width:20, height:20}} className="position-absolute small-star" /></>}
                    {!isActive && <div className="d-flex flex-row justify-content-between align-items-center w-95 m-t-30">
                        <button className="cancel-btn rounded-9 btn-4-alternate" type="button">Cancel</button>
                        <button className="activate-btn rounded-9 btn-4" onClick={showPopup} type="button">Activate</button>
                    </div>}
                </div>
                {isActive && <>
                <Star className="position-absolute top-60 right-50" />
                <Star style={{width:20, height:20}} className="position-absolute top-90 right-80" /></>}
                <p style={{position:isActive?"absolute":"relative", bottom:isActive?50:'', left:0, right:0}} className="text-12 font-weight-4 font-1 col-copyrights-clr text-center mb-0 m-t-60">Copyright © 2024 - walkzero.com</p>
                <p style={{position:isActive?"absolute":"relative", bottom:isActive?20:'', left:0, right:0}} className="text-12 font-weight-4 font-1 col-copyrights-clr text-center mt-0">All Rights Reserved</p></>}
            </div>
            {show && <div className="d-flex flex-column align-items-center bg-col-white activate-popup position-absolute rounded-8 z-index-5">
                <Close onClick={closePopup} className="align-self-end close-popup-x" />
                <div className="d-flex flex-row justify-content-center align-items-start">
                    <Tick className="popup-tick" />
                    <div className="m-l-15">
                        <h1 className="text-20 font-weight-6 font-1 popup-head">Are you sure that you want to activate {name}?</h1>
                        <p className="text-14 font-weight-4 font-1 popup-para">Ensure all the background verification is completed.</p>
                    </div>
                </div>
                <div className="d-flex flex-row justify-content-between align-items-center w-95 m-t-30">
                        <button className="cancel-btn rounded-9 btn-4-alternate" onClick={closePopup} type="button">No, Cancel</button>
                        <button className="activate-btn rounded-9 btn-4" onClick={activate} type="button">Yes, Confirm</button>
                </div>
            </div>}
        </div>  
        </> 
    )
}

export default ActivateAccount