import "./index.scss"
import AreaChart from "../../apex-charts/area"
import BarChart from "../../apex-charts/bar"
import PieChart from "../../apex-charts/pie"
import ClockIn from "../clockin"
import {ReactComponent as Up} from "../../../assets/logos/uparrow.svg"
import {ReactComponent as Down} from "../../../assets/logos/down-arrow.svg"

const data1 = [
    {id:"area1", title:"New Hire", status:"This Month", value:15, icon:<Up />, color:"#54CC8B", fill:"rgba(84, 204, 139, 0.2)", data: [
        {
          month: 'Jan',
          app: 25,
        },
        {
          month: 'Feb',
          app: 130,
        },
        {
          month: 'Mar',
          app: 110,
        },
        {
          month: 'Apr',
          app: 91,
        },
        {
          month: 'May',
          app: 100,
        },
        {
          month: 'Jun',
          app: 150,
        },
        {
          month: 'Jul',
          app: 170,
        },
        {
          month: 'Aug',
          app: 175,
        },
        {
          month: 'Sep',
          app: 200,
        }
    ]},
    {id:"area2", title:"Exits", status:"This Month", value:2, icon:<Down />, color:"#FF5050", fill:"rgba(255, 80, 80, 0.2)", data: [
        {
          month: 'Jan',
          app: 125,
        },
        {
          month: 'Feb',
          app: 130,
        },
        {
          month: 'Mar',
          app: 110,
        },
        {
          month: 'Apr',
          app: 91,
        },
        {
          month: 'May',
          app: 100,
        },
        {
          month: 'Jun',
          app: 80,
        },
        {
          month: 'Jul',
          app: 70,
        },
        {
          month: 'Aug',
          app: 85,
        },
        {
          month: 'Sep',
          app: 70,
        }
    ]},
]

const data2 = [
    {id:"bar1", title:"Employee distribution", color1:"#FFCE78", color2:"#57D2D2"},
]


const Charts = () => {

    return(
        <>
        <div className="d-flex flex-column justify-content-center align-items-center position-relative z-index-1 w-100">
            <div className="d-flex flex-row">
                {data1.map(each => {
                    return <AreaChart key={each.id} item={each} />
                })}
            </div>
            <div className="d-flex flex-row">
                {data2.map(each => {
                    return <BarChart key={each.id} item={each}/>
                })}
                <PieChart />
            </div>
            <ClockIn />
        </div>
        </>
    )
}

export default Charts