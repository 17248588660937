import './index.scss'
import {motion} from "framer-motion"

type AttendanceCardProps = {
    item:{
        title: string 
        content: string 
        img: string
    }
    active: boolean
}

const AttendanceCard = (props:AttendanceCardProps) => {

    const {item, active} = props
    const {title, content, img} = item

    return(
        <motion.div initial={{x:"100vw"}} animate={{x:active?0:0}} transition={{duration:1, type:"tween"}} className='d-flex flex-column justify-content-start align-items-start bg-image rounded-20 p-x-20 p-y-20  mt-5 attendance-card position-relative'> 
            <h1 className='text-14 font-clr'>{title}</h1>
            <p className='text-10 font m-0 mb-3'>{content}</p>
            <img className='w-100 position-absolute attendence-img' src={img} alt='dashbord' />
        </motion.div>
    )
}

export default AttendanceCard